import "./App.css";
import React, { useState } from "react";
import Router from "./Router.js";
import { ToastContainer } from "react-toastify";
import { LayoutProvider } from "./Context/LayoutContext";
import { Provider } from "react-redux";
import { store } from "./store/index";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [disabledTopNav, setDisabledTopNav] = useState(true);
  const [disabledBottomNav, setDisabledBottomNav] = useState(true);

  return (
    <Provider store={store}>
      <LayoutProvider
        value={{
          disabledTopNav,
          setDisabledTopNav,
          disabledBottomNav,
          setDisabledBottomNav,
        }}
      >
        {Router}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </LayoutProvider>
    </Provider>
  );
}

export default App;
