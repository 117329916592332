import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { getCookie, isEmpty } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// user-List API
function UserListPartnerApi(param) {
  // console.log("parmaparamssssss",param)
  // const cmpId = getCookie("companyId");
  // if(param != null){
  const data = JSON.parse(param);
  return axios.request({
    method: "get",
    url: `${urls.BASE_URL}${urls.USER_LIST}?type=${data["type"]}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
  // }else{
  // return axios.request({
  //   method: "get",
  //   url: `${urls.BASE_URL}${urls.USER_LIST}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });
  // }
}

// User-List action effect
export function* userListActionEffect(userListAction) {
  const { payload, resolve, reject } = userListAction;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(UserListPartnerApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      // console.log("user partner123", data)
      return yield put({
        type: actions.USERS_LIST_PARTNER_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// USer-List action watcher
export function* userListActionWatcher() {
  yield takeEvery(actions.USERS_LIST_PARTNER_ACTION, userListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(userListActionWatcher)]);
}
