import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update organisation profile API
function updateOrganisationApi(details) {
  // console.log(details);
  // console.log(details, "params id");
  return axios.request({
    method: "put",
    url: `${urls.BASE_URL}${urls.UPDATE_ORGANISATION}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: details,
  });
}

// Update organisation action effect
export function* updateOrganisationActionEffect(updateOrganisationAction) {
  const { payload, resolve, reject } = updateOrganisationAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(updateOrganisationApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      // yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    // yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update organisation action watcher
export function* updateOrganisationActionWatcher() {
  yield takeLatest(actions.UPDATE_ORGANISATION, updateOrganisationActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateOrganisationActionWatcher)]);
}
