import * as actions from "../actions/types";
const initialState = {
    cronAddStatusCheck:[]
};

const cronAddStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CRON_ADD_STATUS:
            return {
                ...state,
                cronStatusCheck: action.data,
            };
        case actions.CRON_ADD_STATUS_SUCCESS:
            return {
                ...state,
                cronStatusCheck: action.data,
            };
        case actions.CRON_ADD_STATUS_ERROR:
            return {
                ...state,
                cronStatusCheck: action.data,
            };
        default:
            return state;
    }
};
export default cronAddStatusReducer;
