import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// delete secret key - Delete API
function deleteSecretKeyApi(id) {

  // console.log(id, "delete secret id");
  return axios.request({
    method: "delete",
    url: `${urls.BASE_URL}${urls.DELETE_SECRETKEY}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// secret-Delete action effect
export function* secretKeyDeleteActionEffect(secretKeyDeleteAction) {
  const { payload, resolve, reject } = secretKeyDeleteAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(deleteSecretKeyApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.SECRET_LIST_ACTION,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// sercet key-Delete action watcher
export function* secretKeydeleteActionWatcher() {
  yield takeLatest(actions.DELETE_SECRET_KEY, secretKeyDeleteActionEffect);
}

export default function* rootSaga() {
  yield all([fork(secretKeydeleteActionWatcher)]);
}
