import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// add criteria API
function addPipelineCriteriaApi(params) {
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.ADD_PIPELINE_CRITERIA}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Add criteria action effect
export function* addPipelineCriteriaActionEffect(pipelineCriteriaAction) {
  const { payload, resolve, reject } = pipelineCriteriaAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(addPipelineCriteriaApi, payload);
    yield put(toggleBackDrop(false));

    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Add criteria action watcher
export function* addPipelineCriteriaActionWatcher() {
  yield takeLatest(
    actions.ADD_PIPELINE_CRITERIA,
    addPipelineCriteriaActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(addPipelineCriteriaActionWatcher)]);
}
