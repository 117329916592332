import _ from "lodash";

export const setCookie = (cookieName, cookieValue, expiryDays) => {
  if (typeof window === "undefined") return;
  let expires = "";
  if (expiryDays) {
    const d = new Date();
    d.setTime((d.getTime() + expiryDays) * (24 * 60 * 60 * 1000));
    expires = `expires=${d.toUTCString()};`;
  }
  if(window.location.host.includes("localhost")){
    document.cookie = `${cookieName}=${cookieValue};${expires}path=/`;
  }
  else
  {
    document.cookie = `${cookieName}=${cookieValue};${expires}path=/; secure; domain=.roopya.money`;
  }  
};

export const getCookie = (key, cookieString) => {
  if (cookieString) {
    return cookieString.replace(
      new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`),
      "$1"
    );
  } else if (typeof window === "undefined") return "";
  return document.cookie.replace(
    new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`),
    "$1"
  );
};

export const deleteCookie = (cname) => {
  const expires = "expires=Thu, 01-Jan-70 00:00:01 GMT;path=/";
  document.cookie = `${cname}=;${expires}`;
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");
  if(window.location.host.includes("localhost")){
    console.log ("in localhost");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/";
    }
  }
  else {
    console.log ("in live server");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/ domain=.roopya.money";
    }

  }

};

export function isEmpty(obj) {
  let isempty = false;
  const type = typeof obj;

  isempty = isempty || !obj;
  isempty = isempty || type === "undefined"; // if it is undefined
  isempty = isempty || obj === null; // if it is null
  isempty = isempty || (type === "string" && obj.trim() === ""); // if the string is empty
  isempty = isempty || obj === false || obj === 0; // if boolean value returns false
  isempty = isempty || (Array.isArray(obj) && obj.length === 0); // if array is empty
  isempty = isempty || (type === "object" && Object.keys(obj).length === 0); // if object is empty

  return isempty;
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function convertJsonToFormData(data) {
  const formData = new FormData();
  const entries = Object.entries(data);
  for (let i = 0; i < entries.length; i++) {
    // don't try to be smart by replacing it with entries.each, it has drawbacks
    const arKey = entries[i][0];
    let arVal = entries[i][1];
    if (typeof arVal === "boolean") {
      arVal = arVal === true ? 1 : 0;
    }
    if (Array.isArray(arVal)) {
      // if (this.isFile(arVal[0])) {
      //   for (let z = 0; z < arVal.length; z++) {
      //     formData.append(`${arKey}[]`, arVal[z]);
      //   }

      //   continue; // we don't need to append current element now, as its elements already appended
      // }
      if (arVal[0] instanceof Object) {
        for (let j = 0; j < arVal.length; j++) {
          if (arVal[j] instanceof Object) {
            // if first element is not file, we know its not files array
            for (const prop in arVal[j]) {
              if (Object.prototype.hasOwnProperty.call(arVal[j], prop)) {
                // do stuff
                if (!isNaN(Date.parse(arVal[j][prop]))) {
                  // console.log('Valid Date \n')
                  // (new Date(fromDate)).toUTCString()
                  formData.append(
                    `${arKey}[${j}][${prop}]`,
                    new Date(arVal[j][prop])
                  );
                } else {
                  formData.append(`${arKey}[${j}][${prop}]`, arVal[j][prop]);
                }
              }
            }
          }
        }
        continue; // we don't need to append current element now, as its elements already appended
      } else {
        arVal = JSON.stringify(arVal);
      }
    }

    if (arVal === null) {
      continue;
    }
    formData.append(arKey, arVal);
  }
  return formData;
}
