import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// priority - List API
function pipelinePartnerList(params) {
  return axios.request({
    method: "get",
    url: `${urls.BASE_URL}${urls.LIST_PIPELINE_PARTNER}/${params}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// Priority-List action effect
export function* pipelinePartnerListActionEffect(priorityListAction) {
  const { payload, resolve, reject } = priorityListAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(pipelinePartnerList, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.PIPELINE_PARTNER_LIST_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Priority-List action watcher
export function* pipelinePartnerListActionWatcher() {
  yield takeLatest(
    actions.PIPELINE_PARTNER_LIST,
    pipelinePartnerListActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(pipelinePartnerListActionWatcher)]);
}
