import {
    call,
    put,
    takeLatest,
    all,
    fork,
    select,
    takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

// delete setage template - Delete API
function deleteStageTemplateApi(id) {
    const domain = getCookie("domain");
    // console.log("deleteStageTemplate", id)
    return axios.request({
        method: "delete",
        url: `${urls.BASE_URL}${urls.DELETE_STAGE_TEMPLATE_URL}/${id}`,
        // headers: {
        //     "Content-Type": "application/json",
        //     "domain_name": domain === "lenders" ? "" : domain,
        //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
    });
}

// delete stage template action effect
export function* deleteStageTemplateActionEffect(deleteStageTemplateAction) {
    const { payload, resolve, reject } = deleteStageTemplateAction;

    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(deleteStageTemplateApi, payload);
        yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            // console.log("deleteStageTemplate", data);
            // return yield put({
            //   type: actions.SECRET_LIST_ACTION,
            //   data,
            // });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}
// sercet key-Delete action watcher
export function* secretKeydeleteActionWatcher() {
    yield takeLatest(actions.DELETE_STAGE_TEMPLATE, deleteStageTemplateActionEffect);
}

export default function* rootSaga() {
    yield all([fork(secretKeydeleteActionWatcher)]);
}
