import assigntasklistReducer from "./assigntasklistReducer";
import citylistReducer from "./cityListReducer";
import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import getAccessLevelReducer from "./getAccessLevelReducer";
import getAccessLevelReducerByRole from "./getAccessLevelReducerByRole";
import getStageReducer from "./getStageReducer";
import gkDataReducer from "./gkDataReducer";
import lendersFieldsReducers from "./lendersFieldsReducers";
import organisationDetailsReducer from "./orgnisationDetailsReducer";
import pipelineLeadsReducer from "./pipelineLeadsReducer";
import pipelineListReducer from "./pipelineListReducer";
import pipelineLogoListReducer from "./pipelineLogoListReducer";
import pipelinePartnerListReducer from "./pipelinePartnerListReducer";
import priorityListReducer from "./priorityListReducer";
import rolelistReducer from "./roleListReducer";
import { routerReducer } from "react-router-redux";
import secretkeyListReducer from "./secretkeyListReducer";
import statusListReducer from "./statusListReducer";
import tasklistReducer from "./tasklistReducer";
import userProfileReducer from "./userProfileReducer";
import userslistReducer from "./userslistReducer";
import workSpaceReducer from "./workSpaceReducer";
import dashboardDataReducer from "./dashboardDataReducer";
import customerListReducer from "./customerListReducer";
import allPipeLineUserReducer from "./allPipelineUserReducer";
import UploadExcelSubmitReducer from "./UploadExcelReducer";
import stageTemplateUser from "./stageTemplateUser";
import businessDataReducer from "./businessDataReducer";
import customerDetailListReducer from "./customerDetailListReducer";
import applicationDashBoardReducer from "./applicationDashboardReducer.js";
import lenderDashBoardReducer from "./lenderDashboardReducer";
import banklistReducer from "./bankListReducer";
import activeBanklistReducer from "./activeBankListReducer";
import activeBanklistLeadsReducer from "./activeBankListLeadsReducer";
import partnerDashBoardReducer from "./partnerDashBoardReducer";
import marketPlaceDomainReducer from "./marketPlaceDomainReducer";
import saveMarketPlaceDomainReducer from "./saveMarketPlaceDomainReducer";
import singleLeadEligibility from "./singleLeadEligibilityReducer";
import autoLoginListReducer from "./autoLoginListReducer";
import allBankCredentialListReducer from "./allBankCredentialList";
import addBankAutoLoginReducer from "./addBankAutoLoginReducer";
import enableDisableAutoLoginReducer from "./enableDisableAutoLogin";
import getTimeLineDataReducer from "./getTimeLineDataReducer";
import globalNotesDataListReducer from "./globalNotesDataListReducer";
import addGlobalNotesDataReducer from "./addGlobalNotesDataReducer";
import autoLoginCronRefreshReducer from "./autoLoginRefreshReducer";
import cronGetStatusReducer from "./cronGetStatusReducer";
import cronAddStatusReducer from "./cronAddStatusReducer";
import cronUpdateStatusReducer from "./cronUpdateStatusReducer";
import pipelineArchiveDeleteReducer from "./pipelineArchiveDeleteReducer";
import nextStageReducer from "./nextStageReducer";
import leadStageListReducer from "./leadStageListReducer";
import searchPipelineLeadsReducer from "./searchPipelineLeadsReducer";
import searchLeadsByDateReducer from "./searchLeadsByDateReducer";
import multiFilterLeadListReducer from "./multiFilterLeadListReducer";
import customerBREListReducer from "./customerBREListReducer";
import getBusinessEditDataReducer from "./getBusinessEditDataReducer";

export const rootReducers = combineReducers({
  routing: routerReducer,
  customerListData: customerListReducer,
  customerBREListData: customerBREListReducer,
  dashboardDataList: dashboardDataReducer,
  activeBanklistLeadsData: activeBanklistLeadsReducer,
  bankListData: banklistReducer,
  activeBankListData: activeBanklistReducer,
  logoListData: pipelineLogoListReducer,
  taskList: tasklistReducer,
  userList: userslistReducer,
  assigntaskList: assigntasklistReducer,
  statusList: statusListReducer,
  priorityList: priorityListReducer,
  roleList: rolelistReducer,
  organisationData: organisationDetailsReducer,
  // trelloBoardListData: trelloBoardListReducer,
  workSpacetData: workSpaceReducer,
  generalData: gkDataReducer,
  stageData: getStageReducer,
  getAccessLevelData: getAccessLevelReducer,
  getAccessLevelDataByRole: getAccessLevelReducerByRole,
  userProfileData: userProfileReducer,
  pipelineListData: pipelineListReducer,
  lendersFieldsData: lendersFieldsReducers,
  citylistReducer: citylistReducer,
  commonReducer,
  secretkeyListReducer: secretkeyListReducer,
  pipelinePartnerList: pipelinePartnerListReducer,
  pipelineLeads: pipelineLeadsReducer,
  allPipeLineUserReducer: allPipeLineUserReducer,
  // logoutData: logoutReducer,
  UploadExcelSubmitReducer: UploadExcelSubmitReducer,
  stageTemplateUser: stageTemplateUser,
  businessDataReducer: businessDataReducer,
  // getBusinessEditDataReducer: getBusinessEditDataReducer,
  getBusinessEditDataReducer: getBusinessEditDataReducer,
  // customer
  customerDetailListData: customerDetailListReducer,

  applicationDashBoardData: applicationDashBoardReducer,
  lenderDashBoardReducer: lenderDashBoardReducer,
  partnerDashBoardReducer: partnerDashBoardReducer,
  marketPlaceDomainReduer: marketPlaceDomainReducer,
  saveMarketPlaceDomainReducer: saveMarketPlaceDomainReducer,
  singleLeadEligibility: singleLeadEligibility,
  autoLoginListReducer: autoLoginListReducer,
  allBankCredentialListReducer: allBankCredentialListReducer,
  addBankAutoLoginReducer: addBankAutoLoginReducer,
  enableDisableAutoLoginReducer: enableDisableAutoLoginReducer,
  getTimeLineDataReducer: getTimeLineDataReducer,
  globalNotesDataListReducer: globalNotesDataListReducer,
  addGlobalNotesDataReducer: addGlobalNotesDataReducer,
  autoLoginCronRefreshReducer: autoLoginCronRefreshReducer,
  cronGetStatusReducer: cronGetStatusReducer,
  cronAddStatusReducer: cronAddStatusReducer,
  cronUpdateStatusReducer: cronUpdateStatusReducer,

  pipelineArchiveDeleteReducer: pipelineArchiveDeleteReducer,
  nextStageReducer: nextStageReducer,

  leadStageListReducer: leadStageListReducer,
  searchPipelineLeadsReducer: searchPipelineLeadsReducer,
  multiFilterLeadListReducer: multiFilterLeadListReducer,
  searchLeadsByDateReducer: searchLeadsByDateReducer,
});
