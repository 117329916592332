import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";


function bankListApi() {
  const domain = getCookie("domain");
  // console.log(domain);
  const access_token = getCookie("token");
  return axios.request({
    method: "get",
    // url: `https://api.yoindi.com/api/v1/bank/list`,
    url: `${urls.BASE_URL}${urls.BANK_LIST_URL}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}


export function* bankListActionEffect(bankListAction) {
  const { resolve, reject } = bankListAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(bankListApi,);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.BANK_LIST_ACTION_SUCCESS,
        data,
      });
    }
    else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// city-List action watcher
export function* bankListActionWatcher() {
  yield takeLatest(actions.BANK_LIST_ACTION, bankListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(bankListActionWatcher)]);
}
