import * as actions from "../actions/types";

const initialState = {
  uploadExcelDoc: []
};

const UploadExcelSubmitReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPLOAD_EXCEL_SUBMIT:
      return {
        ...state,
        uploadExcelDoc: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    default: {
      return state;
    }
  }
};
export default UploadExcelSubmitReducer;
