import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// User/employment loaction - Delete API
function EmploymentDeleteApi(id) {
  // console.log(id, "delete role id");
  return axios.request({
    method: "delete",
    url: `${urls.BASE_URL}${urls.DELETE_EMPLOYMENTLOCATION}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// User/employment loaction-Delete action effect
export function* EmplocationDeleteActionEffect(employmentDeleteAction) {
  const { payload, resolve, reject } = employmentDeleteAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(EmploymentDeleteApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.DELETE_EMPLOYMENT_LOCATION,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// User/employment loaction-Delete action watcher
export function* emplocationDeleteActionWatcher() {
  yield takeLatest(
    actions.DELETE_EMPLOYMENT_LOCATION,
    EmplocationDeleteActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(emplocationDeleteActionWatcher)]);
}
