import * as actions from "../actions/types";

const initialState = {
  pipelinePartnerListData: [],
};

const pipelinePartnerListReducer = (state = initialState, action) => {
  switch (action.type) {    
    case actions.PIPELINE_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        pipelinePartnerListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default pipelinePartnerListReducer;
