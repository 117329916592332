import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import {
    all,
    call,
    fork,
    put,
    select,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";

import _ from "lodash";
import axios from "../../utils/axios";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// User/Member - Delete API
  function deleteUserDocumentApi(payload) {
    const {data} = JSON.parse(payload);
    return axios.request({
      method: "delete",
      url: `${urls.BASE_URL}${urls.DELETE_USER_DOCUMENT}/${data}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
    });
  }
  
  // user-Delete action effect
  export function* UserDocumentDeleteActionEffect(taskDeleteAction) {
    const { payload, resolve, reject } = taskDeleteAction;
  
    try {
      yield put(toggleBackDrop(true));
      const { data } = yield call(deleteUserDocumentApi, payload);
      yield put(toggleBackDrop(false));
      if (!isEmpty(data)) {
        if (resolve) resolve(data);
        return yield put({
          type: actions.USER_DETAILS,
          data,
        });
      } else {
        yield put(toggleBackDrop(false));
        reject(data);
      }
    } catch (e) {
      yield put(toggleBackDrop(false));
      if (reject) reject(e);
    }
  }
  // user-Delete action watcher
  export function* userDocumentDeleteActionWatcher() {
    yield takeLatest(actions.DELETE_USER_DOCUMENT, UserDocumentDeleteActionEffect);
  }
  
  export default function* rootSaga() {
    yield all([fork(userDocumentDeleteActionWatcher)]);
  }
  