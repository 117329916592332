import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

//Dashboard data API
function dashboardLenderDetailsApi(param) {
  // console.log("action128.2",param)
  const domain = getCookie("domain");
  return axios.request({
    method: "get",
    url: `${urls.BASE_URL}${urls.DASHBOARD_LENDER_DETAILS}${param}`,
    // headers: {
    //   "Content-Type": "application/json",
    //   "domain_name": domain === "lenders" ? "" : domain,
    //   "apiKey": headersKeyFile.headersCommonKey.apiKey,
    //   "apiSecret": headersKeyFile.headersCommonKey.apiSecret
    // },
  });
}

// /Dashboard data effect
export function* dashboardLenderDetailsActionEffect(dashboardDataAction) {
  const { payload, resolve, reject } = dashboardDataAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(dashboardLenderDetailsApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      // console.log("dashboard lender success70", data)
      return yield put({
        type: actions.DASHBOARD_LENDER_DETAILS_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.DASHBOARD_LENDER_DETAILS_ERROR,
      e,
    });
  }
}
///Dashboard data action watcher
export function* dashboardLenderDetailsActionWatcher() {
  yield takeLatest(actions.DASHBOARD_LENDER_DETAILS, dashboardLenderDetailsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(dashboardLenderDetailsActionWatcher)]);
}
