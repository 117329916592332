import * as actions from "../actions/types";

const initialState = {
    partnerDashBoardReduce: []
};

const partnerDashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.DYNAMIC_PARTNER_DASHBOARD:
            return {
                ...state,
                partnerDashBoardReduce: action.data,
                // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
            };
        case actions.DYNAMIC_PARTNER_DASHBOARD_SUCCESS:
            return {
                ...state,
                partnerDashBoardReduce: action.data,
            };
        case actions.DYNAMIC_PARTNER_DASHBOARD_ERROR:
            return {
                ...state,
                partnerDashBoardReduce: action.data,
            }
        default: {
            return state;
        }
    }
};
export default partnerDashBoardReducer;
