import { makeStyles, alpha } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  businessRulesDetails: {
    "&:.MuiButtonBase-root": {
      backgroundColor: "#0a2472 !important",
      color: "#fff !important",
    },
  },
  hdrBusiness: {
    marginTop: 42,
    background: "#fff",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
  breadscrumStyle: {
    cursor: "pointer",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.54)",
  },
  AND: {
    "&:hover": {
      color: "white",
      backgroundColor: "#4169e1 !important",
    },
    "&:active": {
      color: "white",
      backgroundColor: "#4169e1 !important",
    },
  },
  onHoverStyle: {
    cursor: "pointer",
  },
  displayInRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  selAND: {
    color: "white",
    backgroundColor: "#4169e1 !important",
  },
  selOR: {
    color: "white",
    backgroundColor: "#4169e1 !important",
  },
  OR: {
    "&:hover": {
      color: "white",
      backgroundColor: "#4169e1 !important",
    },
    "&:active": {
      color: "white",
      backgroundColor: "#4169e1 !important",
    },
  },
  ruleDetailsName: {
    padding: "20px 0px",
    "& label": {
      fontSize: 16,
      fontWeight: 400,
      display: "block",
    },
    "& input": {
      width: "95%",
      marginTop: "10px",
      padding: "5px 10px",
      border: "1px solid #ccc",
      height: "40px",
      borderRadius: "5px",
    },
  },
  groupBox: {
    border: "2px solid lightblue",
    padding: "20px",
    marginTop: "20px",
    borderRadius: "10px",
  },
  groupBoxBlur: {
    border: "2px solid lightblue",
    padding: "20px",
    marginTop: "20px",
    borderRadius: "10px",
    filter: "blur(20px)",
  },
  formtable: {
    marginTop: 100,
    // overflowX: "scroll",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
    "& span": {
      marginRight: 10,
      fontWeight: "600",
    },
    "& select": {
      border: "1px solid #ccc",
      // height: 50,
      borderRadius: 3,
      [theme.breakpoints.down("sm")]: {
        width: "320px !important",
      },
    },
    "& input": {
      border: "1px solid #ccc",
      // height: 50,
      borderRadius: 3,
      // padding: "0 10px",
      background: "#fff",
    },
  },
  iconStyle: {
    background: "#fff",
    borderRadius: 40,
    cursor: "pointer",
    width: "20px !important",
    height: "20px !important",
  },
}));

export default styles;
