// import { Hidden } from "@material-ui/core";
import { Hidden } from "@mui/material";
import { useContext, useState } from "react";
// import {
//   getAccessLevel,
//   getAccessLevelSuccess,
// } from "../../store/actions/accessLevel";
import { useDispatch, useSelector } from "react-redux";
import BottomNav from "./BottomNav";
import LayoutContext from "../../Context/LayoutContext";
import SideNav from "./SideNav";
import Snackbar from "../../componennts/Snackbar/Snackbar";
import TopNav from "./TopNav";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
import { isEmpty } from "../../../src/utils/common";
import { updateAlertMsg } from "../../store/actions/commonAction";
import useStyles from "./Layout.style";

const Layout = (props) => {
  const dispatch = useDispatch();
  const [drawerWidth, setDrawerWidth] = useState(false);
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();

  const { alertMsg = {} } = useSelector((state) => state.commonReducer);

  const ondrowerWidthChange = () => {
    setDrawerWidth(!drawerWidth);
  };

  return (
    <>
      <Snackbar
        message={alertMsg.message}
        open={!isEmpty(alertMsg)}
        messageType={alertMsg.messageType}
        closeNotification={() => dispatch(updateAlertMsg({}))}
        action={alertMsg.action}
      />

      {!layoutContext.disabledTopNav && (
        <Hidden>
          <div className={classes.mobileViewSideNav}>
            <SideNav
              ondrowerWidthChange={ondrowerWidthChange}
              setDrawerWidth={setDrawerWidth}
            />
          </div>
          <TopNav />
        </Hidden>
      )}
      <div
        className={
          layoutContext.disabledTopNav
            ? classes.container11
            : drawerWidth
            ? classes.containerRight11
            : classes.containerRightFull11
        }
        // style={{ border: "2px solid red" }}
      >
        <div className={classes.mainPaper}>{props.children}</div>
      </div>
      {!layoutContext.disabledBottomNav && (
        <Hidden smUp>
          <BottomNav />
        </Hidden>
      )}
    </>
  );
};

// const mapStateToProps = (state) => ({
//   getAccessLevelDatas: state.getAccessLevelData,
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getAccessLevel,
//       getAccessLevelSuccess,
//     },
//     dispatch
//   );

// export default connect(mapStateToProps, mapDispatchToProps)(Layout);
export default Layout;
