import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import { PanoramaHorizontalTwoTone } from "@material-ui/icons";
import * as headersKeyFile from "../sagas/headersCommonKeyData";


// Add member API
function addStageTemplate(params) {
    const domain = getCookie("domain");
    console.log("domain add stage", domain)
    return axios.request({
        method: "post",
        url: `${urls.BASE_URL}${urls.ADD_STAGE_TEMPLATE_URL}`,
        // headers: {
        //     "Content-Type": "application/json",
        //     "domain_name": domain === "lenders" ? "" : domain,
        //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
        data: params,
    });
}

// Add member action effect
export function* addStageTemplateActionEffect(loginAction) {
    const { payload, resolve, reject } = loginAction;
    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(addStageTemplate, payload);
        yield put(toggleBackDrop(false));
        // console.log(" addStageTemplateSaga", data);
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            // return yield put({
            //     type: actions.ADD_,
            //     payload: data,
            // });
            // console.log("succes ADD Pipeline User", data);
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}

// Sign-in action watcher
export function* addStageTemplateActionWatcher() {
    yield takeLatest(actions.ADD_STAGE_TEMPLATE, addStageTemplateActionEffect);
}

export default function* rootSaga() {
    yield all([fork(addStageTemplateActionWatcher)]);
}
