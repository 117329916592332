import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// User/Member - Delete API
function deleteTaskDocumentApi(id) {
  // console.log(id, "delete document id");
  return axios.request({
    method: "delete",
    url: `${urls.BASE_URL}${urls.DELETE_TASK_DOCUMENT}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// user-Delete action effect
export function* deleteTaskDocumentActionEffect(deleteTaskDocumentAction) {
  const { payload, resolve, reject } = deleteTaskDocumentAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(deleteTaskDocumentApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.TASK_DETAILS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

// user-Delete action watcher
export function* deleteTaskDocumentActionWatcher() {
  yield takeLatest(
    actions.DELETE_TASK_DOCUMENT,
    deleteTaskDocumentActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(deleteTaskDocumentActionWatcher)]);
}
