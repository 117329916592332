import {
    call,
    put,
    takeLatest,
    all,
    fork,
    select,
    takeEvery,
  } from "redux-saga/effects";
  import * as actions from "../actions/types";
  import axios from "../../utils/axios";
  import _ from "lodash";
  import * as urls from "../../utils/urls";
  import { isEmpty, getCookie } from "../../utils/common";
  import { toggleBackDrop } from "../actions/commonAction";
  
  //Asign Task - List API
  function AsignTaskListApi() {
    const userId = getCookie("user_id");
    // console.log(userId);
   
    return axios.request({
      method: "get",
      url: `${urls.BASE_URL}${urls.ASSIGN_TASK_LIST}/${userId}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
    });
  
  }
  
  //Assign Task-List action effect
  export function* assigntaskListActionEffect(taskListAction) {
    const { payload, resolve, reject  } = taskListAction;
  
    try {
      yield put(toggleBackDrop(true));
      const { data } = yield call(AsignTaskListApi, payload);
      yield put(toggleBackDrop(false));
      if (!isEmpty(data)) {
        if (resolve) resolve(data);
        return yield put({
          type: actions.ASSIGN_TASK_LIST_ACTION_SUCCESS,
          data,
        });
      } else {
        yield put(toggleBackDrop(false));
        reject(data);
      }
      
    } catch (e) {
      yield put(toggleBackDrop(false));
      if (reject) reject(e);
  
    }
  }
  //Assign Task-List action watcher
  export function* assigntaskListActionWatcher() {
    yield takeLatest(actions.ASSIGN_TASK_LIST_ACTION, assigntaskListActionEffect);
  }
  
  export default function* rootSaga() {
    yield all([fork(assigntaskListActionWatcher)]);
  }
  