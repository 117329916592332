import * as actions from "../actions/types";

const initialState = {
  domainList: {},
  nextStageList: {},
};

const nextStageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.NEXT_STAGE:
      return {
        ...state,
        nextStageList: action.data,
      };
    case actions.NEXT_STAGE_SUCCESS:
      return {
        ...state,
        nextStageList: action.data,
      };
    case actions.NEXT_STAGE_ERROR:
      return {
        ...state,
        nextStageList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default nextStageReducer;
