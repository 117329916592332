import * as actions from "../actions/types";

const initialState = {
  dashboardDataList: [],
};

const dashboardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.DASHBOARD_DATA_ACTION:
      return {
        ...state,
        dashboardDataList: action.data,
      };
    case actions.DASHBOARD_DATA_ACTION_SUCCESS:
      return {
        ...state,
        dashboardDataList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default dashboardDataReducer;
