import {
    call,
    put,
    takeLatest,
    all,
    fork,
    select,
    takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

function editStageTemplateApi(param, id) {
    // console.log("edit stage template saga", paramID);
    const domain = getCookie("domain");
    let urlw = `${urls.BASE_URL}${urls.EDIT_STAGE_TEMPLATE_URL}${param.paramID}`
    // console.log("edit stage template saga url", urlw)
    return axios.request({
        method: "put",
        url: urlw,
        // headers: {
        //     "Content-Type": "application/json",
        //     "domain_name": domain === "lenders" ? "" : domain,
        //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
        data: param.payload

    });
}

export function* editStageTemplateEffect(educationDeleteAction) {
    const { payload, paramID, resolve, reject } = educationDeleteAction;
    // console.log("educationDeleteAction", paramID);
    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(editStageTemplateApi, educationDeleteAction);
        yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            // console.log("edit data stage template4", data);
            if (resolve) resolve(data);
            // return yield put({
            //     type: actions.DELETE_EMPLOYMENT_EDUCATION,
            //     data,
            // });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}

export function* editStageTemplateWatcher() {
    yield takeLatest(
        actions.EDIT_STAGE_TEMPLATE, editStageTemplateEffect
    );
}

export default function* rootSaga() {
    yield all([fork(editStageTemplateWatcher)]);
}
