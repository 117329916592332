import * as actions from "./types";

export function verifyEmailAction(param, resolve, reject) {
  return {
    type: actions.EMAIL_VERIFY,
    payload: param,
    resolve,
    reject,
  };
}
