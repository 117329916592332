import { Dialog as MuiDialog, withStyles } from "@material-ui/core";

import React from "react";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = withStyles((theme) => ({
  paperWidthSm: {
    maxWidth: 1000,
  },
}))(MuiDialog);

export default function CustomDialog(props) {
  const { children, open, handleClose, classes, ...other } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      // className={classes && classes.backdropStyle}
      fullWidth
      maxWidth={props.maxWidth}
      PaperProps={props.PaperProps}
      {...other}
    >
      {children}
    </Dialog>
  );
}
