import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update task document API
function updateTaskDocumentApi(params) {
  // console.log(params);

  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.UPDATE_TASK_DOCUMENT}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Update task document action effect
export function* updateTaskDocumentActionEffect(updateTaskDocumentAction) {
  const { payload, resolve, reject } = updateTaskDocumentAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateTaskDocumentApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update task document action watcher
export function* updateTaskDocumentActionWatcher() {
  yield takeLatest(
    actions.UPDATE_TASK_DOCUMENT,
    updateTaskDocumentActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(updateTaskDocumentActionWatcher)]);
}
