import useStyles from "./TopNav.style";
import * as routeTo from "../../utils/routeConstants";
// import {
//   Avatar,
//   Box,
//   Grid,
//   Menu,
//   MenuItem,
//   DialogTitle,
//   DialogContent,
//   InputLabel,
//   TextField,
//   FormHelperText,
// } from "@material-ui/core";

// * MUI Components
import {
  Avatar,
  Box,
  Grid,
  Menu,
  MenuItem,
  DialogTitle,
  DialogContent,
  InputLabel,
  TextField,
  FormHelperText,
  AppBar,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  InputBase,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

// * Icons
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// * Images
import roopyaMoneyLogo from "../../assests/img/roopyaNewLogo.png";
import NavBarMobile from "../../assests/img/topNavSideDrawer.png";

// * Components
import CustomDialog from "../Dialog/dialog";
import AddUserModal from "../../pages/Users/AddUser";

import { NavLink } from "react-router-dom";
import { getCookie } from "../../utils/common";

// import AppBar from "@material-ui/core/AppBar";
// import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
// import InputBase from "@material-ui/core/InputBase";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";

import { useState } from "react";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { connect } from "react-redux";
import { deleteAllCookies } from "../../utils/common";
import { logout } from "../../store/actions/logout";
import { useHistory, withRouter } from "react-router-dom";
import { changePassword } from "../../store/actions/changePassword";
import { updateAlertMsg } from "../../store/actions/commonAction";

const TopNav = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [addMenuIsOpen, setAddMenuIsOpen] = useState(null);
  const [open, setOpen] = useState(false);
  const [rightDrawer, setRightDrawer] = useState(false);
  const name = getCookie("name");
  const history = useHistory();

  const [modalTitle, setModalTitle] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [errMsg, seterrMsg] = useState("");

  const logout = () => {
    return new Promise((resolve, reject) => {
      props.logout(resolve, reject);
    })
      .then((result) => {
        // console.log(result);
        deleteAllCookies();
        if (window.location.host.includes("localhost")) {
          // window.location.replace('users/createpassword');
          window.location.replace(window.location.origin);
          // window.location.reload();
          // window.location.replace('users/createpassword');
          // history.push("/login");
        } else {
          // window.location.replace('https://roopya.money/');
          // window.location.replace('users/createpassword');
          window.location.replace(`https://lenders.roopya.money/#/login`);
          // window.location.reload();
        }
        setAnchorEl(null);
      })
      .catch((err) => {
        // console.log({ err });
      });
  };
  const moveAddTaskPage = () => {
    setAddMenuIsOpen(null);
    history.push(`/addtask`);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddMenuClose = () => {
    setAddMenuIsOpen(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
    handleAddMenuClose(null);
  };

  const handleCloseUserModal = () => {
    setOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    setRightDrawer(!rightDrawer);
  };

  const drawerList = [
    { displayText: "Dashboard", onClick: `/${routeTo.DASHBOARD}` },
    { displayText: "Tasks", onClick: `/${routeTo.TASKLISTPAGE}` },
    { displayText: "Users", onClick: `/${routeTo.USERS}` },
    { displayText: "Pipelines", onClick: `/${routeTo.PIPELINES}` },
    { displayText: "Profile", onClick: `/${routeTo.USERPROFILE}` },
    { displayText: "Settings", onClick: `/${routeTo.SETTINGS}` },
    { displayText: "BusinessRules", onClick: `/${routeTo.BUSINESSRULES}` },
  ];

  const list = () => (
    <Box
      sx={{ width: "100%", marginTop: 40 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {drawerList.map((list, index) => (
          <NavLink to={list.onClick} className={classes.navLink} key={index}>
            <ListItem button key={list.displayText}>
              <ListItemText primary={list.displayText} />
            </ListItem>
          </NavLink>
        ))}
      </List>
      <Divider />
    </Box>
  );

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setModalTitle("");
    seterrMsg({});
    setDetails({});
  };

  const handleChangePassword = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const onSubmitFunction = () => {
    if (!details.password) {
      seterrMsg({
        textfieldErr: "password",
        messgae: "Please enter old password !!",
      });
    } else if (!details.new_password) {
      seterrMsg({
        textfieldErr: "new_password",
        messgae: "Please enter new password !!",
      });
    } else if (!details.confirm_password) {
      seterrMsg({
        textfieldErr: "confirm_password",
        messgae: "Please enter confirm_password !!",
      });
    } else if (details.new_password !== details.confirm_password) {
      seterrMsg({
        textfieldErr: "confirm_password",
        messgae: "Please enter correct password !!",
      });
    } else {
      return new Promise((resolve, reject) => {
        props.changePassword(details, resolve, reject);
      })
        .then((result) => {
          props.updateAlertMsg({
            message: (result && result.message) || "",
            messageType: "success",
          });
          handleEditModalClose();
        })
        .catch((err) => {
          const { response } = err;
          const { data } = response;
          props.updateAlertMsg({
            message:
              data.message || "Something went wrong , please try again later",
            messageType: "error",
          });
        });
    }
  };

  const openDialog = (title) => {
    return (
      <CustomDialog
        open={editModalOpen}
        handleClose={() => handleEditModalClose()}
      >
        <DialogTitle
          style={{
            width: 500,
            textAlign: "center",
            borderBottom: "1px solid #cccc",
          }}
        >
          Change your password
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} sm={10}>
            <InputLabel
              className={classes.inputLabelStyle}
              InputLabelProps={{ required: true }}
            >
              Old password
            </InputLabel>
            <TextField
              id="password"
              name="password"
              placeholder="Enter old password"
              onChange={handleChangePassword}
              error={errMsg}
              fullWidth
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              InputLabelProps={{
                shrink: false,
                required: true,
                className: classes.bootstrapFormLabel,
              }}
            />
            {errMsg.textfieldErr == "password" && (
              <FormHelperText error>{errMsg.messgae}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} sm={10}>
            <InputLabel
              className={classes.inputLabelStyle}
              InputLabelProps={{ required: true }}
            >
              New password
            </InputLabel>
            <TextField
              id="new_password"
              name="new_password"
              placeholder="Enter new password"
              onChange={handleChangePassword}
              fullWidth
              error={errMsg}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              InputLabelProps={{
                shrink: false,
                required: true,
                className: classes.bootstrapFormLabel,
              }}
            />
            {errMsg.textfieldErr == "new_password" && (
              <FormHelperText error>{errMsg.messgae}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={10}>
            <InputLabel
              className={classes.inputLabelStyle}
              InputLabelProps={{ required: true }}
            >
              Confirm password
            </InputLabel>
            <TextField
              id="confirm_password"
              name="confirm_password"
              placeholder="Enter confirm password"
              error={errMsg}
              onChange={handleChangePassword}
              fullWidth
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              InputLabelProps={{
                shrink: false,
                required: true,
                className: classes.bootstrapFormLabel,
              }}
            />
            {errMsg.textfieldErr == "confirm_password" && (
              <FormHelperText error>{errMsg.messgae}</FormHelperText>
            )}
          </Grid>

          <div className={classes.editModalButtonContainer}>
            <Button
              variant="contained"
              className={classes.modalButtonStyle}
              onClick={() => handleEditModalClose()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.modalButtonStyle}
              onClick={onSubmitFunction}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </CustomDialog>
    );
  };

  return (
    <>
      <CssBaseline />
      {open && (
        <AddUserModal modalopen={open} handleClose={handleCloseUserModal} />
      )}
      {openDialog(modalTitle)}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        style={{ zIndex: 9999999999 }}
        variant="menu"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEditModalOpen(true);
            setAnchorEl(null);
            setModalTitle("Change your password");
          }}
        >
          Change password
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={addMenuIsOpen}
        keepMounted
        open={Boolean(addMenuIsOpen)}
        variant="menu"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleAddMenuClose}
      >
        <MenuItem onClick={handleOpen}>Add Member</MenuItem>
        <MenuItem onClick={moveAddTaskPage}>Add Task</MenuItem>
      </Menu>

      <AppBar
        position="fixed"
        color="inherit"
        // className={clsx(classes.appBar, classes.appbarBackground)}
        // className={`${classes.appBar} ${classes.appbarBackground}`}
      >
        <Toolbar className={classes.toolBar}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.compContainer}
          >
            <div className={classes.leftSection}>
              <NavLink
                to={`${routeTo.DASHBOARD}`}
                className={clsx(classes.navLink, classes.navLinkHome)}
              >
                <Button
                  className={clsx(classes.menuButton, classes.logoButton)}
                >
                  <Typography className={classes.taskManagementStyle}>
                    <img
                      style={{ marginLeft: "-20px", width: "80%" }}
                      src={roopyaMoneyLogo}
                      alt="img"
                    />
                  </Typography>
                </Button>
              </NavLink>
            </div>
            <div
              style={{
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={classes.mobileViewRightSection}>
                <img
                  alt=""
                  src={NavBarMobile}
                  style={{
                    height: 30,
                    width: 30,
                    cursor: "pointer",
                    filter: "contrast(100) brightness(0)",
                  }}
                  onClick={toggleDrawer(true)}
                />
                <Drawer
                  anchor="top"
                  open={rightDrawer}
                  onClose={toggleDrawer(false)}
                >
                  {list()}
                </Drawer>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon className={classes.searchIconStyle} />
                </div>
                <InputBase
                  placeholder="Search here"
                  className={classes.searchInputStyle}
                  classes={{
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            </div>

            <div className={classes.rightSection}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 10,
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    marginBottom: "0em",
                    color: "#8898aa",
                    fontFamily: "Poppins",
                  }}
                >
                  Welcome, {name}
                </p>

                <div>
                  <Avatar
                    alt={name}
                    src="/static/images/avatar/1.jpg"
                    onClick={handleClick}
                    style={{
                      marginLeft: 10,
                      height: 32,
                      width: 32,
                      color: "#fff",
                      background: "#0a2472",
                    }}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      // logoutSuccess,
      updateAlertMsg,
      changePassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopNav));
