import * as actions from "../actions/types";

const initialState = {
    addStage: [],
    getSingleStage: [],
    getAllStage: [],
    editStage: [],
    deleteStage: []
};

const stageTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ADD_STAGE_TEMPLATE:
            return {
                ...state,
                addStage: action.paramAdd
                // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
            };
        case actions.GET_STAGE_TEMPLATE:
            return {
                ...state,
                getSingleStage: action.paramID,
            };
        case actions.GET_ALL_STAGE_TEMPLATE:
            return {
                ...state,
                getAllStage: action.data,
            }
        case actions.EDIT_STAGE_TEMPLATE:
            return {
                ...state,
                editStage: action.data,
            }
        case actions.DELETE_STAGE_TEMPLATE:
            return {
                ...state,
                addStage: state.addStage.filter((todo, i) => i !== action.payload),
            }
        default: {
            return state;
        }
    }
};
export default stageTemplateReducer;
