import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Market Place domain API
function marketPlaceDomainListApi(param) {
  return axios.request({
    method: "get",
    url: `${urls.BASE_URL}${urls.MARKET_PLACE_DOMAIN_LIST_URL}${param}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// Market Place domain effect

export function* marketPlaceDomainListActionEffect(marketPlaceDomain) {
  const { payload, resolve, reject } = marketPlaceDomain;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(marketPlaceDomainListApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.MARKET_PLACE_DOMAIN_LIST_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.MARKET_PLACE_DOMAIN_LIST_ERROR,
      e,
    });
  }
}
//Market Place domain watcher
export function* marketPlaceDomainListActionWatcher() {
  yield takeLatest(
    actions.MARKET_PLACE_DOMAIN_LIST,
    marketPlaceDomainListActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(marketPlaceDomainListActionWatcher)]);
}
