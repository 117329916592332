import * as actions from "../actions/types"

const initialState = {
    leadEligibility: []
}

const singleLeadEligibility = (state = initialState, action) => {

    switch (action.type) {
        case actions.SINGLE_LEAD_ELIGIBILITY:
            return {
                ...state,
                leadEligibility: action.data
            };
        case actions.SINGLE_LEAD_ELIGIBILITY_SUCCESS:
            return {
                ...state,
                leadEligibility: action.data
            };
        case actions.SINGLE_LEAD_ELIGIBILITY_ERROR:
            return {
                ...state,
                leadEligibility: action.data
            };
        default: {
            return state;
        };
    };
}
export default singleLeadEligibility
