import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty,getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// User/Member - Delete API
function LogoDeleteApi(id) {
  // console.log(id, "delete user id");
  return axios.request({
    method: "delete",
    url: `${urls.BASE_URL}${urls.DELETE_PIPELINE_LOGO}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// user-Delete action effect
export function* LogoDeleteActionEffect(taskDeleteAction) {
  const { payload, resolve, reject } = taskDeleteAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(LogoDeleteApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.LOGO_LIST_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// user-Delete action watcher
export function* memberDeleteActionWatcher() {
  yield takeLatest(actions.DELETE_PIPELINE_LOGO, LogoDeleteActionEffect);
}

export default function* rootSaga() {
  yield all([fork(memberDeleteActionWatcher)]);
}
