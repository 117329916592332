import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "../BusinessRulesDetails.style";
import { Autocomplete, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect } from "react";
import useStyleModal from "../../../pages/UserProfile/UserProfile.style";
import moment from "moment/moment";

export default function BusinessEditSingleRule(props) {
  const classes = useStyles();
  //   console.log("props.ruleList", props.ruleList);
  const [operatorsList, setOperatorsList] = React.useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [resultantList, setResultantList] = React.useState([]);
  const modalStyle = useStyleModal();
  //   const [field, setField] = React.useState("");
  const today = new Date();
  const [empDate, setEmpDate] = React.useState({
    date: moment(today).format("YYYY-MM-DD"),
  });
  const [type, setType] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(Date());
  //   useEffect(() => {
  //     props.getBusinessRuleData.data.map((item) => {
  //       if (item.fieldName === field) {
  //         let operList = [];
  //         item.BusinessRuleDetails.map((item) => {
  //           let obj = {
  //             label: item.conditions,
  //             value: item.condition_value,
  //           };
  //           operList.push(obj);
  //         });
  //         setOperatorsList(operList);
  //       }
  //     });
  //   }, [field]);

  //   const newFunc = (e) => {};
  //   useEffect(() => {
  //     setType("select");
  //   }, []);

  const handleSelectField = (e, rule, value) => {
    // let { value } = e.target;
    props.getBusinessRuleData.data.map((item) => {
      if (item.fieldName === value.value) {
        props.getFieldName(rule, value.value);
        let operList = [];
        item.BusinessRuleDetails.map((item) => {
          let obj = {
            label: item.conditions,
            value: item.condition_value,
          };
          operList.push(obj);
          let stringList = item.input_value.slice(
            1,
            item.input_value.length - 1
          );

          let splitedList = stringList.split(",");
          let newList = splitedList.map((item) => {
            return item.slice(1, item.length - 1);
          });

          setResultantList(newList);
          setType(item.input_type);
          let typeSel = item.input_type;
          props.getType(rule, typeSel);
          props.getInputList(rule, newList);
        });
        props.getOperatorsList(rule, operList);
        setOperatorsList(operList);
      }
    });
  };

  const handleChangeOperator = (e, rule, value) => {
    // console.log("resultantList", resultantList);
    // const { value } = e.target;
    props.getSelectedAction(rule, value.value);
  };

  const handleSelectedValue = (e, rule) => {
    // setSelectedDate(date);
    const { name, value } = e.target;
    if (name === "date") {
      setEmpDate({
        ...empDate,
        date: moment(value).format("YYYY-MM-DD"),
      });
      props.getSelectedValue(rule, moment(value).format("YYYY-MM-DD"));
    } else if (name === "number") {
      let number = parseInt(value);
      props.getSelectedValue(rule, number);
    } else {
      props.getSelectedValue(rule, value);
    }
    // console.log("value=>", value);
  };
  return props.ruleList.map((rule, idx) => {
    // console.log("inside=>", value.type);
    const fieldName = `fieldName-${rule.id}`;
    const operator = `operator-${rule.id}`;
    const resultField = `resultField-${rule.id}`;

    let options =
      props.getBusinessRuleData &&
      props.getBusinessRuleData.data &&
      props.getBusinessRuleData.data.map((item) => {
        return { label: item.showFieldName, value: item.fieldName };
      });

    const defValue =
      options &&
      options.length > 0 &&
      options.find((option) => option.value === rule.fieldName);

    const defOperator =
      rule.operatorList &&
      rule.operatorList.length > 0 &&
      rule.operatorList.find((option) => option.value === rule.action);

    return (
      <div key={rule.id}>
        {/* <Stack spacing={2} direction="row">
          <div className={classes.displayInRow}>
            <div>
              <FormControl
                variant="standard"
                style={{
                  margin: "1px",
                  minWidth: "150px",
                  marginRight: "10px",
                }}
              >
                {options && options.length > 0 && (
                  <Autocomplete
                    options={options}
                    id="flat-demo"
                    defaultValue={defValue}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => handleSelectField(e, rule, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Field"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
              {rule.operatorList && rule.operatorList.length > 0 && (
                <FormControl
                  variant="standard"
                  style={{
                    margin: "1px",
                    minWidth: "150px",
                    marginRight: "10px",
                  }}
                >
                  <Autocomplete
                    options={rule.operatorList}
                    id="flat-demo"
                    defaultValue={defOperator}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) =>
                      handleChangeOperator(e, rule, value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Field"
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              )}
              <FormControl
                variant="standard"
                style={{
                  margin: "1px",
                  minWidth: "150px",
                  marginRight: "10px",
                }}
              >
                {rule.type.length > 0 && rule.type === "select" ? (
                  <React.Fragment>
                    <InputLabel id="demo-simple-select-standard-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id={resultField}
                      value={rule.value}
                      name="select"
                      onChange={(e, value) =>
                        handleSelectedValue(e, rule, value)
                      }
                    >
                      {rule.input_value.length > 0
                        ? rule.input_value.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })
                        : ""}
                    </Select>
                  </React.Fragment>
                ) : rule.type === "number" ? (
                  <React.Fragment>
                    <TextField
                      id="standard-basic"
                      label="Enter number"
                      variant="standard"
                      name="number"
                      type="number"
                      defaultValue={rule.value}
                      onChange={(e, value) =>
                        handleSelectedValue(e, rule, value)
                      }
                    />
                  </React.Fragment>
                ) : rule.type === "date" ? (
                  <React.Fragment>
                    <TextField
                      id="standard-basic"
                      label="Date"
                      name="date"
                      defaultValue={empDate.date}
                      InputLabelProps={{ shrink: true, required: false }}
                      variant="standard"
                      type="date"
                      onChange={(e, value) =>
                        handleSelectedValue(e, rule, value)
                      }
                    />
                  </React.Fragment>
                ) : rule.type === "text" ? (
                  <React.Fragment>
                    <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                    <TextField
                      id="standard-basic"
                      name="text"
                      value={rule.value}
                      label="Enter text"
                      variant="standard"
                      onChange={(e, value) =>
                        handleSelectedValue(e, rule, value)
                      }
                      type="text"
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
              </FormControl>
            </div>
            {idx === 0 ? (
              ""
            ) : (
              <div>
                <Grid item xs={4} style={{ marginTop: "10px" }}>
                  <DeleteIcon
                    onClick={() => props.onDelete(rule)}
                    className={classes.onHoverStyle}
                  />
                </Grid>
              </div>
            )}
          </div>
        </Stack> */}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl
              variant="standard"
              style={{
                margin: "1px",
                minWidth: "100%",
                marginRight: "10px",
              }}
            >
              {options && options.length > 0 && (
                <Autocomplete
                  options={options}
                  id="flat-demo"
                  defaultValue={defValue}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => handleSelectField(e, rule, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Field"
                      variant="standard"
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {rule.operatorList && rule.operatorList.length > 0 && (
              <FormControl
                variant="standard"
                style={{
                  margin: "1px",
                  minWidth: "100%",
                  marginRight: "10px",
                }}
              >
                <Autocomplete
                  options={rule.operatorList}
                  id="flat-demo"
                  defaultValue={defOperator}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => handleChangeOperator(e, rule, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Field"
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl
              variant="standard"
              style={{
                margin: "1px",
                minWidth: "100%",
                marginRight: "10px",
              }}
            >
              {rule.type.length > 0 && rule.type === "select" ? (
                <React.Fragment>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id={resultField}
                    value={rule.value}
                    name="select"
                    onChange={(e, value) => handleSelectedValue(e, rule, value)}
                  >
                    {rule.input_value.length > 0
                      ? rule.input_value.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })
                      : ""}
                  </Select>
                </React.Fragment>
              ) : rule.type === "number" ? (
                <React.Fragment>
                  <TextField
                    id="standard-basic"
                    label="Enter number"
                    variant="standard"
                    name="number"
                    type="number"
                    defaultValue={rule.value}
                    onChange={(e, value) => handleSelectedValue(e, rule, value)}
                  />
                </React.Fragment>
              ) : rule.type === "date" ? (
                <React.Fragment>
                  <TextField
                    id="standard-basic"
                    label="Date"
                    name="date"
                    defaultValue={
                      rule && rule.value.length > 0
                        ? moment(rule.value).format("YYYY-MM-DD")
                        : empDate.date
                    }
                    InputLabelProps={{ shrink: true, required: false }}
                    variant="standard"
                    type="date"
                    onChange={(e, value) => handleSelectedValue(e, rule, value)}
                  />
                </React.Fragment>
              ) : rule.type === "text" ? (
                <React.Fragment>
                  <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                  <TextField
                    id="standard-basic"
                    name="text"
                    value={rule.value}
                    label="Enter text"
                    variant="standard"
                    onChange={(e, value) => handleSelectedValue(e, rule, value)}
                    type="text"
                  />
                </React.Fragment>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {idx === 0 ? (
              ""
            ) : (
              <div>
                <Grid item xs={4} style={{ marginTop: "10px" }}>
                  <DeleteIcon
                    onClick={() => props.onDelete(rule)}
                    className={classes.onHoverStyle}
                  />
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  });
}
