import * as actions from "../actions/types";

const initialState = {
  customerListData: [],
};

const customerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CUSTOMER_ACTION:
      return {
        ...state,
        customerListData: action.data,
      };
    case actions.CUSTOMER_ACTION_SUCCESS:
      return {
        ...state,
        customerListData: action.data,
      };
    default: {
      return {...state,
        customerListData: [],
    };
  }
  }
};
export default customerListReducer;
