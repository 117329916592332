import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";
// Add member API
function allPipelineUserApi(params) {
    const domain = getCookie("domain");
    return axios.request({
        method: "get",
        url: `${urls.BASE_URL}${urls.PIPELINE_ALL_USER}${params}`,
        // headers: {
        //     // Authorization: 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOjEsInVzZXIiOnsiaWQiOjEsImlzX2FjdGl2ZSI6dHJ1ZSwiaXNfZGVsZXRlZCI6ZmFsc2UsImlzX3JlYWRlZCI6ZmFsc2UsInBlcm1pc3Npb25faWQiOjAsImNyZWF0ZWRfYnkiOjAsInVwZGF0ZWRfYnkiOjAsImRlbGV0aW9uRGF0ZSI6bnVsbCwiZmlyc3ROYW1lIjoiU3VzaGlsIiwibGFzdE5hbWUiOiJHdXB0YSIsImZ1bGxOYW1lIjoiU3VzaGlsIEd1cHRhIiwiZW1haWwiOiJzdXNoaWxAZ2VvYWxnby5jb20iLCJwYXNzd29yZCI6IiQyYiQxMiRMT1R4d0h2QXd3UUNlZkhiTVMzamdPa3h4WFhRLllXd1JTenB6enJacldKSUJtOGNNalVvaSIsInBob25lIjpudWxsLCJyb2xlX2lkIjoxLCJzaWduYXR1cmUiOiIwIiwiaW1hZ2UiOiIuL3B1YmxpYy9zdXNoaWwvcHJvZmlsZS8xNjQzNjA1MDY5NjE3LnBuZyIsImNvbG9yQ29kZSI6IiNGRjAwMDAiLCJmaXJlYmFzZV90b2tlbiI6bnVsbCwicHdkX3Rva2VuIjpudWxsLCJpZFRva2VuIjpudWxsLCJ1aWQiOm51bGwsInB3ZF9nZW5lcmF0b3IiOmZhbHNlLCJjcmVhdGVkQXQiOiIyMDIxLTEyLTE1VDA5OjI2OjMyLjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIyLTAzLTI4VDEyOjEyOjIzLjAwMFoifSwiaWF0IjoxNjU0MTY5MzUzLCJleHAiOjE2ODU3MDUzNTN9.ga9P093bY4cw_WaOk4VLkWvpa-TSmY1l-3BSjjngvns",
        //     "Content-Type": "application/json",
        //     "domain_name": domain === "lenders" ? "" : domain,
        //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
        // data: params,
    });
}

// Add member action effect
export function* allPipeLineUserListActionEffect(loginAction) {
    const { payload, resolve, reject } = loginAction;
    // console.log("dosdsdmadsmkds");
    try {
        // yield put(toggleBackDrop(true));
        const { data } = yield call(allPipelineUserApi, payload);
        // yield put(toggleBackDrop(false));
        // console.log("dataPIpelineeeUser", data.token);
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            // console.log("dattatatata", data);
        } else {
            // console.log("dattatatataerroror")
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        // console.log("errorormmdmdm", e)
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}

// Sign-in action watcher
export function* allPipelineUserActionWatcher() {
    yield takeLatest(actions.PIPELINE_ALL_USER, allPipeLineUserListActionEffect);
}

export default function* rootSaga() {
    yield all([fork(allPipelineUserActionWatcher)]);
}
