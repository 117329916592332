import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { isEmpty, setCookie,getCookie } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// Sign-in API
function loginApi(params) {
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.SIGN_IN}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Sign-in action effect
export function* loginActionEffect(loginAction) {
  // console.log("login_data_1", loginAction);
  const { payload, resolve, reject } = loginAction;
  // console.log("login_data_payload", payload);
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(loginApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data) && data.data && data.data.token) {      
      const { token, domain_name } = data.data;
      // console.log("token", token);
      setCookie("token", token, 1);
      const d = new Date();
      d.setTime((d.getTime() + 1) * (24 * 60 * 60 * 1000));
      var expires = `expires=${d.toUTCString()};`;
      // document.cookie = "token="+token+"; " + expires + " GMT; path=/; secure; domain=.roopya.money";
      if(!window.location.host.includes("localhost")){
        const subDomain = window.location.host.split(".")[0];
        console.log("ddddddddddddd")
        if(subDomain !== domain_name){
          window.location.replace(`https://${domain_name}.roopya.money/#/dashboard`);
        }
      }
      // console.log("user login ---------", data);
      
      if (resolve) {
        resolve(data);
      }
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    // console.log("login_data_2", e);
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

// Sign-in action watcher
export function* loginActionWatcher() {
  yield takeLatest(actions.LOGIN_ACTION, loginActionEffect);
}

export default function* rootSaga() {
  yield all([fork(loginActionWatcher)]);
}
