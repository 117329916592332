import * as actions from "./types";

export const userList = (param, resolve, reject) => ({
  type: actions.USERS_LIST_ACTION,
  payload: param,
  resolve,
  reject,
});

export const userListSuccess = () => ({
  type: actions.USERS_LIST_ACTION_SUCCESS,
});
