import * as routeTo from "../../utils/routeConstants";
import {
  CssBaseline,
  Divider,
  Drawer,
  List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  // Tooltip,
  // Typography,
} from "@material-ui/core";
import {
  Typography,
  Tooltip,
  ListItemText,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import { FaUsers } from "react-icons/fa";
import { GiGears } from "react-icons/gi";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@material-ui/icons/Group";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { NavLink } from "react-router-dom";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import React from "react";
import clsx from "clsx";
import useStyles from "./SideNav.style";
import { getCookie } from "../../utils/common";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SettingsIcon from "@mui/icons-material/Settings";

export default function SideNav(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { ondrowerWidthChange } = props;
  const dashboard_permission = JSON.parse(getCookie("dashboard_permission"));
  const setting_permission = JSON.parse(getCookie("setting_permission"));
  const pipeline_permission = JSON.parse(getCookie("pipeline_permission"));
  const customer_permission = JSON.parse(getCookie("customer_permission"));
  const bank_permission = JSON.parse(getCookie("bank_permission"));
  const employee_permission = JSON.parse(getCookie("employee_permission"));

  const handleDrawerOpen = () => {
    setOpen(!open);
    ondrowerWidthChange();
  };

  const domain = getCookie("domain");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        elevation={7}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <List className={classes.listStyle}>
          {((dashboard_permission !== undefined && dashboard_permission) ||
            dashboard_permission.read ||
            dashboard_permission.delete ||
            dashboard_permission.write ||
            dashboard_permission.create) && (
            <NavLink to={`/${routeTo.DASHBOARD}`} className={classes.navLink}>
              <ListItem button key="DashBoard">
                <ListItemIcon>
                  <Tooltip
                    title="Dashboard"
                    aria-label="dashboard"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <DashboardRoundedIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Dashboard
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {((customer_permission !== undefined && customer_permission.read) ||
            customer_permission.read ||
            customer_permission.delete ||
            customer_permission.write ||
            customer_permission.create) && (
            <NavLink to={`/${routeTo.CONTACTS}`} className={classes.navLink}>
              <ListItem button key="Customer">
                <ListItemIcon>
                  <Tooltip
                    title="Customer "
                    aria-label="Customer "
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <GroupIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Customers
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {((pipeline_permission !== undefined && pipeline_permission.read) ||
            pipeline_permission.delete ||
            pipeline_permission.write ||
            pipeline_permission.create) && (
            <NavLink to={`/${routeTo.PIPELINES}`} className={classes.navLink}>
              <ListItem button key="PipeLine">
                <ListItemIcon>
                  <Tooltip
                    title="CRM"
                    aria-label="CRM"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <AppsIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      CRM
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {((bank_permission !== undefined && bank_permission.read) ||
            bank_permission.delete ||
            bank_permission.read ||
            bank_permission.write ||
            bank_permission.create) && (
            <NavLink
              to={`/${routeTo.BANKLISTPAGE}`}
              className={classes.navLink}
            >
              <ListItem button key="Bank">
                <ListItemIcon>
                  <Tooltip
                    title="Lenders"
                    aria-label="Banks"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <AccountBalanceIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Lenders
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {((dashboard_permission && dashboard_permission.read) ||
            dashboard_permission.delete ||
            dashboard_permission.write ||
            dashboard_permission.create) && (
            <NavLink
              to={`/${routeTo.LOSYSTEM}`}
              className={classes.navLink}
              style={{ display: "none" }}
            >
              <ListItem button key="PipeLine">
                <ListItemIcon>
                  <Tooltip
                    title="LOS"
                    aria-label="LOS"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <ListAltIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      LOS
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {((dashboard_permission && dashboard_permission.read) ||
            dashboard_permission.delete ||
            dashboard_permission.write ||
            dashboard_permission.create) && (
            <NavLink
              to={`/${routeTo.LMSYSTEM}`}
              className={classes.navLink}
              style={{ display: "none" }}
            >
              <ListItem button key="PipeLine">
                <ListItemIcon>
                  <Tooltip
                    title="LMS"
                    aria-label="LMS"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <ListAltIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      LMS
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {/* {((employee_permission !== undefined && employee_permission.read) ||
            employee_permission.read ||
            employee_permission.delete ||
            employee_permission.write ||
            employee_permission.create) && (
            <NavLink to={`/${routeTo.PARTNERS}`} className={classes.navLink}>
              <ListItem button key="PipeLine">
                <ListItemIcon>
                  <Tooltip
                    title="PARTNERS"
                    aria-label="PARTNERS"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <GroupAddIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Partners
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )} */}

          {/* {((employee_permission !== undefined && employee_permission.read) ||
            employee_permission.read ||
            employee_permission.delete ||
            employee_permission.write ||
            employee_permission.create) && (
            <NavLink to={`/${routeTo.USERS}`} className={classes.navLink}>
              <ListItem button key="Users">
                <ListItemIcon>
                  <Tooltip
                    title="Users"
                    aria-label="users"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <PersonRoundedIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Users
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )} */}

          {((setting_permission !== undefined && setting_permission.read) ||
            setting_permission.read ||
            setting_permission.write ||
            setting_permission.create ||
            setting_permission.delete) && (
            <NavLink
              to={`/${routeTo.BUSINESSRULES}`}
              className={classes.navLink}
            >
              <ListItem button key="Chat">
                <ListItemIcon>
                  <Tooltip
                    title="Business Rules"
                    aria-label="settings"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <span>
                      {" "}
                      <GiGears className={classes.sidebarNavIcon} />
                    </span>
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Business Rules
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}

          {domain === "admin" &&
            ((setting_permission !== undefined && setting_permission.read) ||
              setting_permission.read ||
              setting_permission.write ||
              setting_permission.create ||
              setting_permission.delete) && (
              <NavLink to={`/${routeTo.Clients}`} className={classes.navLink}>
                <ListItem button key="Chat">
                  <ListItemIcon>
                    <Tooltip
                      title="Clients"
                      aria-label="clients"
                      placement="right"
                      className={classes.tooltipSize}
                    >
                      <span>
                        <FaUsers className={classes.sidebarNavIcon} />
                      </span>
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    className={clsx(classes.listText, {
                      [classes.visibility]: !open,
                    })}
                    primary={
                      <Typography type="body1" className={classes.titlestyle}>
                        Clients
                      </Typography>
                    }
                  />
                </ListItem>
              </NavLink>
            )}
          <Divider style={{ backgroundColor: "white" }} />

          {((setting_permission !== undefined && setting_permission.read) ||
            setting_permission.read ||
            setting_permission.write ||
            setting_permission.create ||
            setting_permission.delete) && (
            <NavLink to={`/${routeTo.SETTINGS}`} className={classes.navLink}>
              <ListItem button key="Chat">
                <ListItemIcon>
                  <Tooltip
                    title="Settings"
                    aria-label="settings"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                    <SettingsIcon className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Settings
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )}
          {/* {
          ((setting_permission !== undefined && setting_permission.read) ||
            setting_permission.read ||
            setting_permission.write ||
            setting_permission.create ||
            setting_permission.delete) && (
            <NavLink to={`/${routeTo.Clients}`} className={classes.navLink}>
              <ListItem button key="Chat">
                <ListItemIcon>
                  <Tooltip
                    title="Clients"
                    aria-label="clients"
                    placement="right"
                    className={classes.tooltipSize}
                  >
                   
                    <FaUsers className={classes.sidebarNavIcon} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  className={clsx(classes.listText, {
                    [classes.visibility]: !open,
                  })}
                  primary={
                    <Typography type="body1" className={classes.titlestyle}>
                      Clients
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
          )
          } */}

          <ArrowForwardIosIcon
            onClick={handleDrawerOpen}
            className={
              open ? classes.rotatearrowIconStyle : classes.arrowIconStyle
            }
            title={open ? "Close Sidebar" : "Open Sidebar"}
          />
        </List>
      </Drawer>
    </div>
  );
}
