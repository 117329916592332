import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

function searchLeadsByDateApi(params) {
  let id = params.id;
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.SEARCH_LEADS_BY_DATE}/${id}`,
    data: params,
  });
}

export function* searchLeadsByDateActionEffect(searchPipelineLeadsAction) {
  const { payload, resolve, reject } = searchPipelineLeadsAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(searchLeadsByDateApi, payload);
    yield put(toggleBackDrop(false));

    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.SEARCH_LEADS_BY_DATE_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
      return yield put({
        type: actions.SEARCH_LEADS_BY_DATE_FAILED,
        data,
      });
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.SEARCH_LEADS_BY_DATE_FAILED,
    });
  }
}

export function* searchLeadsByDateActionWatcher() {
  yield takeLatest(
    actions.SEARCH_LEADS_BY_DATE,
    searchLeadsByDateActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(searchLeadsByDateActionWatcher)]);
}
