import "./CustomLoader.css";
export default function CustomLoader(props) {
  return (
    <main>
      <div className={props.isPage ? `newPagLoader` : `loader`}>
        <div className="loader-inner line-scale">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </main>
  );
}
