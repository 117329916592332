import React, { useEffect, useState } from "react";
import useStyles from "../BusinessRulesDetails.style";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { v4 } from "uuid";
import BusinessEditSingleRule from "./BusinessEditSingleRule";
import useModalStyles from "../../../pages/UserProfile/UserProfile.style";

export default function BusinessEditGroup(props) {
  //   const [singleRule, setSingleRule] = useState({
  //     ruleList: [{ id: v4(), fieldName: "" }],
  //   });
  const classesModal = useModalStyles();
  const [and, setAnd] = useState(false);
  const [or, setOr] = useState(false);
  const classes = useStyles();

  const OnConjunctionClick = (type, groupId, condition) => {
    props.getConditionValue(type, groupId);
  };

  const addSingleRule = (ruleList, groupId) => {
    let newRuleList = [
      ...ruleList,
      { id: v4(), fieldName: "", action: "", type: "", input_value: [] },
    ];
    props.getNewRuleList(newRuleList, groupId);
  };

  const getType = (rule, type) => {
    props.groupList.map((data) => {
      let filterRule = data.ruleList.filter((data) => data === rule);
      if (filterRule.length > 0) {
        props.getUpdatedRuleList(filterRule, data.id, type);
      }
    });
  };

  const getOperatorsList = (rule, operatorList) => {
    props.groupList.map((data) => {
      let filterRule = data.ruleList.filter((data) => data === rule);
      if (filterRule.length > 0) {
        props.getUpdatedOperatorList(filterRule, data.id, operatorList);
      }
    });
  };

  const onDelete = (value) => {
    props.groupList.map((data) => {
      let filterRuleList = data.ruleList.filter((data) => data != value);
      if (filterRuleList.length > 0) {
        props.getNewRuleList(filterRuleList, data.id);
      }
    });
  };

  console.log("props Group List=>", props.groupList);

  const getInputList = (rule, inputList) => {
    props.groupList.map((data) => {
      let filterRule = data.ruleList.filter((data) => data === rule);
      if (filterRule.length > 0) {
        props.getUpdateInputList(filterRule, data.id, inputList);
      }
    });
  };
  const getFieldName = (rule, fieldName) => {
    props.groupList.map((data) => {
      let filterRule = data.ruleList.filter((data) => data === rule);
      if (filterRule.length > 0) {
        props.getUpdateFieldName(filterRule, data.id, fieldName);
      }
    });
  };
  const getSelectedAction = (rule, action) => {
    props.groupList.map((data) => {
      let filterRule = data.ruleList.filter((data) => data === rule);
      if (filterRule.length > 0) {
        props.getUpdateAction(filterRule, data.id, action);
      }
    });
  };
  const getSelectedValue = (rule, action) => {
    props.groupList.map((data) => {
      let filterRule = data.ruleList.filter((data) => data === rule);
      if (filterRule.length > 0) {
        props.getUpdateValue(filterRule, data.id, action);
      }
    });
  };
  //   const { ruleList } = singleRule;
  console.log("group=>", props.groupList);
  //   return props.groupList && props.groupList.id && props ? (
  return props.groupList.map((value, idx) => {
    return (
      <div key={value.id}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Box className={classes.groupBox} id={value.id}>
              <div className={classes.displayInRow}>
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                >
                  <Button
                    onClick={() =>
                      OnConjunctionClick("[Op.and]", value.id, value.condition)
                    }
                    className={`${classes.AND} ${
                      value.condition === "[Op.and]" && classes.selOR
                      // : classes.selAND
                    }`}
                  >
                    AND
                  </Button>
                  <Button
                    onClick={() =>
                      OnConjunctionClick("[Op.or]", value.id, value.condition)
                    }
                    className={`${classes.AND} ${
                      value.condition === "[Op.or]" &&
                      //  classes.selOR :
                      classes.selAND
                    }`}
                  >
                    OR
                  </Button>
                </ButtonGroup>
                <div>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="text"
                      onClick={() => addSingleRule(value.ruleList, value.id)}
                      className={classesModal.modalButtonStyle}
                    >
                      + Rule
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => props.onDeleteGroup(value)}
                      className={classesModal.modalButtonStyle}
                    >
                      - Group
                    </Button>
                  </Stack>
                </div>
              </div>
              <div>
                <BusinessEditSingleRule
                  ruleList={value.ruleList}
                  onDelete={onDelete}
                  getBusinessRuleData={props.getBusinessRuleData}
                  getType={getType}
                  getOperatorsList={getOperatorsList}
                  getInputList={getInputList}
                  getFieldName={getFieldName}
                  getSelectedAction={getSelectedAction}
                  getSelectedValue={getSelectedValue}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  });
  //   : (
  //     <React.Fragment></React.Fragment>
  //   );
}
