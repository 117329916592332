import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update leads API
function updateLeadsApi(params) {
  // console.log(params);
  let details = params.updateData;
  let id = params.id;
  // console.log(id, details, "params id");
  return axios.request({
    method: "put",
    url: `${urls.BASE_URL}${urls.UPDATE_LEADS_DATA}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: details,
  });
}

// Update leads action effect
export function* updateLeadsActionEffect(updatePipelineAction) {
  const { payload, resolve, reject } = updatePipelineAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateLeadsApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update leads action watcher
export function* updateLeadsActionWatcher() {
  yield takeLatest(actions.UPDATE_LEADS, updateLeadsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateLeadsActionWatcher)]);
}
