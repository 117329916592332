import * as actions from "../actions/types";

const initialState = {
  domainList: [],
  multiFilterLeadList: {},
};

const multiFilterLeadListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MULTI_FILTER_LEAD_LIST:
      return {
        ...state,
        multiFilterLeadList: action.data,
      };
    case actions.MULTI_FILTER_LEAD_LIST_SUCCESS:
      return {
        ...state,
        multiFilterLeadList: action.data,
      };
    case actions.MULTI_FILTER_LEAD_LIST_ERROR:
      return {
        ...state,
        multiFilterLeadList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default multiFilterLeadListReducer;
