import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Single Organisation Details API
function organisationDetailsApi() {
  const id = getCookie("user_id");
  return axios.request({
    method: "get",
    url: `${urls.BASE_URL}${urls.ORGANISATION_DETAILS_ACTION}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// Single Organisation Details effect

export function* orgDetailsActionEffect(orgDetailsAction) {
  const { payload, resolve, reject } = orgDetailsAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(organisationDetailsApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.ORGANISATION_DETAILS_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
//Single organisation Details action watcher
export function* orgDetailsActionWatcher() {
  yield takeLatest(actions.ORGANISATION_DETAILS_ACTION, orgDetailsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(orgDetailsActionWatcher)]);
}
