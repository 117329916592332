import * as actions from "./types";

export function getBusinessRule() {
  return {
    type: actions.GET_BUSINESS_DATA,
    // payload:data,
    // resolve,
    // reject,
  };
}
export function getBusinessRuleSuccess() {
  return {
    type: actions.GET_BUSINESS_SUCCESS_DATA,
    // payload:data,
    // resolve,
    // reject,
  };
}