import * as urls from "../../utils/urls";

import { Button, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";

import LayoutContext from "../../Context/LayoutContext";
import Tab from "@material-ui/core/Tab";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { setCookie } from "../../utils/common";
import { updateAlertMsg } from "../../store/actions/commonAction";
import { useHistory } from "react-router-dom";
import useStyles from "./VerifyEmail.style";
import verifyEmail from "../../assests/img/verify-email.gif";
import { verifyEmailAction } from "../../store/actions/verifyEmailAction";

const VerifyEmail = (props) => {
  // console.log(props);
  const classes = useStyles();
  const history = useHistory();
  let url = props.location.search;
  let getToken = queryString.parse(url, { parseNumbers: true });
  const [verifyToken, setVerifyToken] = useState(getToken.verifytoken);
  const layoutContext = useContext(LayoutContext);

  // useLayoutEffect(() => {
  //   layoutContext.setDisabledTopNav(false);
  //   layoutContext.setDisabledBottomNav(true);
  // }, []);

  // useEffect(() => {
  //   let url = `${urls.BASE_URL}`;

  //   window.location.replace(
  //     "http://localhost:3000/#/verify/email?verifytoken=1b0717c1eb1f91cf6675c2b2ec7da5689f0a894c39e0518d2f746f0b167c3476aa0d39dea0f76c646cd0029da56226f2859890802b8a8ab379027d03a9a97f03"
  //   );
  // }, []);

  const verifyEmailFunction = async (e) => {
    // console.log(e);
    try {
      const data_1 = await new Promise((resolve, reject) => {
        let data = {
          email_verification_token: verifyToken,
        };
        props.verifyEmailAction(data, resolve, reject);
      });
      // console.log(data_1, "verify data");
      let newmessage = data_1.message;
      const { domain_name } = data_1.data;
      setCookie("domainName", domain_name, 1);
      props.updateAlertMsg({
        message: newmessage || "",
        messageType: "success",
      });

      // history.push("/users/createpassword");
      // history.go();
      // window.location.replace(
      //   `https://${domain_name}.roopya.money/#/users/createpassword`
      // );
      window.location.replace(`https://lenders.roopya.money/#/login`);
    } catch (err) {
      // console.log({ err }, "err");
      props.updateAlertMsg({
        message: err.response.data.message || "",
        messageType: "error",
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontSize: 22,
            color: "black",
            wordSpacing: 5,
            letterSpacing: 2,
          }}
        >
          Verify your email
        </Typography>
        <Typography variant="body2" style={{ fontSize: 14, color: "black" }}>
          You will need to verify your email to complete registration
        </Typography>
        <img
          src={verifyEmail}
          style={{
            width: "15%",
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <div style={{ textAlign: "center", marginTop: 10, width: "100%" }}>
          {/* <Typography variant="body2" style={{fontSize: 14, color: "black", textAlign: "center", width: "100%"}}>An email has been sent to your mail id with a link to verify your account.If you have not received the email after a few minutes, please check your spam folder </Typography> */}
        </div>
        <Button
          variant="contained"
          className={classes.verifyEmailButtonStyle}
          onClick={verifyEmailFunction}
        >
          Verify Email
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // taskId: state.taskId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ verifyEmailAction, updateAlertMsg }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
