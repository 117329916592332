export const LOGIN = "login";
export const REGISTER_PAGE = "register";
export const CRIF_DETAILS = "crif";
export const RepaymentHistory = "repaymentHistory";
export const HOME_PAGE = "/";
export const DASHBOARD = "dashboard";
export const TASK = "task";
export const TASKLISTPAGE = "taskpage";
export const SUCCESS = "success";
export const USERS = "users";
export const Clients = "clients";
export const USER_PROFILE = "users/profile/:userId";
export const PIPELINEDETAILS = "pipeline/details/:pipelineId";
export const CHAT = "chat";
export const ADDTASK = "addtask";
export const ALLPIPELINEDETAILSPAGE = "allpipelinedetailspage";
export const ALLPIPELINEPUSHEDPAGE = "allpipelinepushedspage";
export const ADDPIPELINEFORM = "addpipeline";
export const MobileOTPVerification = "mobile/otp";
export const ADDUSERMODALFORM = "addusermodalform";
export const EDITTASK = "edittask";
export const TASKDETAILS = "taskdetails";
export const SETTINGS = "settings";
export const FORGOTPASSWORD = "forgot_password";
export const CREATEPASSWORD = "password/create";
export const PIPELINES = "pipelines";
export const APPLICATIONSTAGE = "applicationstage";
export const AddleadSourceModel = "addleadsource";
// export const LEADSDASHBOARD = "pipeline/:pipeline_id/leads";
export const LEADSDASHBOARD = "pipeline/leads";
export const LEADS = "leadsPage";
export const CONTACTS = "contacts";
export const CUSTOMER_DETAILS = "customer_details";
export const SSILS = "settings/userdetails";
export const NODATAFOUND = "nodatafound";
export const APPLICATION = "application";
export const APPLICATIONDETAILS = "applicationdetails";
export const VERIFYEMAIL = "verify/email";
export const USERPROFILE = "profile";
export const PROFILEDETAILS = "profile/details";
export const USERS_LOGIN_PAGE = "users/createpassword";
export const FORMCREATOR = "pipeline/form_creator";
export const PARTNERS = "partners/";
export const LMSYSTEM = "lmsystem";
export const LOSYSTEM = "losystem";
export const TYPE_ONE = "companylist";
export const LEADSSETTINGSFORM = "leadpullcriteria";
export const LEADSDETAILS = "leadsdetails";
export const CUSTOMERDETAILSPAGE = "customer_details_page";
export const BUSINESSRULES = "business_rules";
export const BUSINESSRULESDETAILS = "business_rules_details";
export const BUSINESSRULESEDITDETAILS = "business_rules_edit_details/:id";
export const WORKFLOWLIST = "wok_flow_List";
export const WORKFLOWDETAILS = "wok_flow_Details";
export const BANKLISTPAGE = "bank_details_page";

export const PARTNERSTABLE = "partners_table";
export const PipelineSettings = "pipeline_settings";
