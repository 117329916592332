import { alpha, makeStyles } from "@material-ui/core/styles";
import pdfDemo from "../../assests/img/pdfDemo.png";

const styles = makeStyles((theme) => ({
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
    borderRadius: 4,
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    // width: 300,
    // width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Red Hat Display",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.1rem rgba(10, 36, 114, 0.5)",
    },
  },
  root: {
    minWidth: 275,
  },
  // saveProfileImageIcon: {
  //   "&:hover": {
  //     fontSize: "22px",
  //   },
  // },
  // clearProfileImageIcon: {
  //   "&:hover": {
  //     fontSize: "22px",
  //   },
  // },
  positionNew: {
    marginTop: "80%",
  },
  initialpos: {
    marginTop: "35%",
  },
  tabroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.white,
    height: "max-content",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  whenNoDataFOund: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
  },
  editAvatarButtons: {
    zIndex: "1000",
    position: "absolute",
    marginTop: "40%",
    marginLeft: "28%",
    "&:hover": {
      backdropFilter: "brightness(50%)",
      // filter: "brightness(50%)",
    },
  },
  onHoverBlur: {
    // width: "180px",
    // height: "160px",
    borderRadius: "10px",
    display: "block",
    "&:hover": {
      backdropFilter: "brightness(50%)",
    },
  },
  blurBox: {
    backdropFilter: "brightness(50%)",
    zIndex: "1000",
    color: "white",
    width: "105px",
    height: "105px",
    display: "block",
  },
  previewText: {
    marginTop: "38%",
    zIndex: 1000,
    position: "absolute",
    marginLeft: "20%",
    color: "white",
    fontWeight: "400",
  },
  previewDocSample: {
    // backgroundImage: `url(${pdfDemo})`,
    backgroundSize: "cover",
    height: "105px",
    width: "105px",
    display: "block",
    marginTop: "3%",
    marginLeft: "3%",
  },
  avatarImages: {
    boxShadow: "0px 4px 4px 0px #777777",
    borderRadius: "10px",
    // position: "relative",
    // cursor: "pointer",
    height: "160px",
    width: "180px",
    display: "block",
    // "& .editIc": {
    //   display: "none",
    // },

    // paddingTop: "30%",
    // "&:hover": {
    //   filter: "brightness(50%)",
    // },
  },
  editIcons: {
    width: "180px",
    height: "160px",
    display: "block",
    paddingTop: "36%",
    // "&:hover": {
    //   display: "block",
    // },
  },

  // editIc:{

  // },

  saveBtn: {
    borderRadius: "4px",
    width: "158px",
    height: "50px",
    backgroundColor: "#2B378D",
    color: "white",
  },
  cancelBtn: {
    borderRadius: "4px",
    width: "105px",
    height: "50px",
    color: "#E43226",
    border: "1px solid #E43226",
    backgroundColor: "white",
  },
  cancelBtnText: {
    fontSize: "15px",
    fontFamily: "Poppins",
    lineHeight: "23px",
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  userPageContent: {
    // marginRight : theme.spacing(1, 'auto')
    height: "max-content",
    marginLeft: 0,
    paddingLeft: "20px",
    marginRight: 0,
    paddingRight: "10px",
    backgroundColor: "#f4f5ff",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: "10px",
    },
  },
  showIcon: {
    fontWeight: 200,
    marginRight: "8px",
    marginLeft: "10px",
  },
  userdBackground: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // marginTop: 42,
    // padding: 16,
    padding: theme.spacing(1, 0, 1, 0),
    alignItems: "center",
    // paddingLeft: 4,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  searchIconStyle: {
    color: "#333333",
    zIndex: 1,
  },
  myteamIconStyle: {
    color: "#0A2472",
    fontWeight: "bold",
    fontFamily: "Red Hat Display",
    fontSize: 17,
  },

  basicInfostyle: {
    fontWeight: "bold",
    fontFamily: "Red Hat Display",
    fontSize: 15,
    marginTop: "10px",
    paddingTop: "10px",
  },

  lastModifystyle: {
    fontWeight: "bold",
    fontFamily: "Red Hat Display",
    fontSize: 15,
    marginLeft: "5px",
    marginTop: "8px",
  },
  lastModifyinfostyle: {
    fontFamily: "Red Hat Display",
    fontSize: 15,
    marginLeft: "10px",
    marginTop: "8px",
  },
  stageInfo: {
    height: "34px",
    marginTop: "7px",
    fontFamily: "Red Hat Display",
    width: "65%",
    marginRight: 16,
  },
  deleteIconStyle: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "4px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      width: "26%",
      marginTop: "25px",
      marginLeft: 8,
    },
  },
  editiconStyle: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "4px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "23px",
      width: "26%",
      marginTop: "25px",
    },
  },
  buleticon: { fontSize: "12px", marginLeft: "15px", marginTop: "15px" },
  modifyGrid: { display: "flex", marginBottom: 30, width: "100%" },
  checkNotProfileData: {
    fontWeight: 500,
    color: "#bbb",
  },
  backbuttonStyle: {
    height: 20,
    width: 20,
    marginRight: 10,
    marginLeft: 10,
    cursor: "pointer",
  },
  createSubtaskStyle: {
    width: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    padding: "10px",
  },
  loremGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  stageCaption: {
    fontSize: 14,
    fontFamily: "Red Hat Display",
    fontWeight: 900,
    marginTop: "10px",
  },
  headerPadding: {
    marginLeft: "5px !important",
  },
  gridBox: {
    border: "1px solid #eeeeee",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    overflow: "none",
  },
  userNameGrid: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "30vh",
    height: "auto",
    marginRight: 16,
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    position: "relative",
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  rootStyle: {
    // padding: "9.5px 14px",
    // height: "38px",
    // marginTop: "5px",
    // marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "5px",
      height: "44px",
    },
    "& > div": {
      "& > div": {
        marginRight: "-18px !important",
      },
    },
  },
  formbootstrapInput: {
    borderRadius: 4,
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    width: "100%",
    // width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.1rem rgba(10, 36, 114, 0.5)",
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px",
    width: 300,
    // width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Red Hat Display",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.1rem rgba(10, 36, 114, 0.5)",
      overflow: "auto",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  userContactGrid: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "20vh",
    height: "auto",
    marginRight: 16,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 16,
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  userReportGrid: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "24vh",
    height: "auto",
    marginRight: 16,
    display: "block",
    flexDirection: "column",
    paddingLeft: 16,
    marginBottom: 20,
    paddingBottom: 1,
    // overflowX: "scroll",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  detailRightSectionContainer: {
    width: "100%",
    // backgroundColor: "white",
    minHeight: "80vh",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainleftSectionGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "auto",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
      overflowX: "hidden",
      display: "block",
      // height: "570px",
    },
  },
  loremstyle: {
    fontWeight: "bold",
    fontFamily: "Red Hat Display",
    fontSize: 15,
    marginTop: "10px",
    paddingTop: "10px",
    color: "#000000",
    marginRight: "10px",
  },
  loremInfostyle: {
    fontFamily: "Red Hat Display",
    fontSize: 15,
    marginTop: "10px",
    paddingTop: "10px",
    color: "#000000",
    marginLeft: "10px",
    paddingLeft: "10px",
  },

  userheaderOption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },

  adduserButtonstyle: {
    backgroundColor: "#0A2472",
    color: "white",
    height: 30,
    [theme.breakpoints.down("sm")]: {
      height: 42,
      width: "60%",
      backgroundColor: "#0a2472",
      top: "0px !important",
      color: "white",
      left: "40% !important",
      marginRight: 90,
    },
  },

  teamListTextImage: {
    height: 28,
    width: 28,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 4,
  },
  backgroundStyle: {
    display: "flex",
    padding: "3px",
    fontSize: "17px",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  taskdetailsPage: {
    margin: "0px 25px",
    background: "#ffffff",
    height: "100%",
    width: "96.5%",
    padding: 8,
    // margin: 10
  },
  containerstyle: {
    flexDirection: "column",
    margin: "6px 2px 0px 2px",
    padding: 16,
    paddingBottom: 0,
  },

  gridContainer: {
    display: "block",
    // flexDirection: "row",
    width: "100%",
    // justifyContent: "space-between",
    padding: "9 0",
    alignItems: "center",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  contenHeader: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "initial",
    fontFamily: "Red Hat Display",
  },
  contentHeadertag: {
    color: "#0052CC",
    fontSize: "12px",
    marginLeft: "4px",
  },
  contentText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  typographyStyle: {
    fontSize: "15px",
    color: "black",
    fontFamily: "Red Hat Display",
  },
  progressRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  imgGrid: {
    display: "flex",
    flexDirection: "column",
  },
  chip: {
    margin: theme.spacing(0.5),
    background: "#0A2472",
    fontFamily: "Red Hat Display",
    color: "white",
    fontWeight: 500,
  },
  boxStyle: {
    width: "23rem",
    height: "13rem",
    border: "1px solid black",
    borderWidth: "thick",
    borderColor: "#5C80BC",
  },
  imgGridStyle: {
    display: "flex",
    flexDirection: "row",
  },
  secondBox: {
    width: "15rem",
    height: "6rem",
    border: "1px solid black",
    borderWidth: "thick",
    borderColor: "#5C80BC",
  },
  thirdBox: {
    width: "7rem",
    height: "6rem",
    border: "1px solid black",
    borderWidth: "thick",
    borderColor: "#5C80BC",
  },

  startdateGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 0,
    marginTop: 10,
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mobileViewEnable: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: 20,
    },
  },
  readmoreGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "-54px 789px 1px 0px", //what is this padding 789px and -54px?
  },

  datetitle: {
    color: "#000000",
    fontSize: "12px",
    fontWeight: 400,
    marginLeft: "2px",
    marginTop: "6px",
  },

  startdateValue: {
    color: "#000000",
    fontSize: "15px",
    margin: " 4px 4px 23px 0px",
    fontWeight: "bold",
  },

  spaceDivforDate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  checkboxColor: {
    color: "#1f1717",
  },

  teamListHeader: {
    margin: "6px 30px 5px",
    marginTop: 6,
    display: "flex",
    flexDirection: "column",
  },
  teamheaderTextTyle: {
    color: "#000000",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "0px 1px 1px 1px",
  },

  teamListCardGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap ",
  },

  teamlistCardBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    paddingRight: 10,
  },

  cardContentPadding: {
    padding: "1px 5px 3px 0px",
  },

  readmoreButtonDiv: {
    textAlign: "end",
  },
  // addUsermargin: {
  //   alignItems: "left",
  //   marginLeft: 10,
  // },
  //why you write this css class bullet/pos/title?
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  teamCardContent: {
    fontFamily: "Red Hat Display",
    fontWeight: "bold",
    fontSize: 12,
    margin: 19,
    color: "#333333",
    padding: "1px",
  },
  taskCardStyle: {
    padding: "1px",
    marginTop: 1,
  },
  taskProjectStyle: {
    fontSize: 12,
    fontFamily: "Red Hat Display",
    fontWeight: "normal",
    marginTop: 1,
    display: "flex",
    marginLeft: 45,
    color: "#6B778C",
  },
  avatarGroupstyle: {
    marginTop: 11,
    margin: 0,
    justifyContent: "center",
  },

  stagesGrid: {
    margin: "6px 30px 5px",
    display: "flex",
    flexDirection: "column",
  },
  subavatarGroupstyle: {
    marginRight: "7px",
    marginLeft: 48,
    margin: 0,
  },

  cradFlexStyle: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },

  formControl: {
    width: "20%",
    margin: "2px",
    minWidth: 120,
  },
  subtaskGrid: {
    margin: "2px",
    minWidth: 120,
    marginTop: "5px",
  },

  selectPriority: {
    color: "black",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: "0px",
    marginTop: "0px",
  },
  readmoreDes: {
    color: "#000000",
    fontSize: "12px",
  },
  desGrid: { margin: "14px 4px 54px 56px", width: "70%" },
  readmoretitle: {
    font: "Red Hat Display",
    lineHeight: "3.5em",
    color: "#000000",
    fontSize: "15px",
    fontWeight: "bold",
    marginLeft: "2px",
    marginTop: "6px",
  },
  readmoreAvatardiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  readmoreCommettext: {
    color: "#000000",
    fontSize: "12px",
  },
  commenttextGrid: {
    margin: "0px 4px 64px 55px",
    width: "70%",
  },
  commentstyle: {
    marginLeft: "20px",
    marginTop: "20px",
    padding: "30px",
  },
  readmorePage: {
    background: "#F3F3F3",
    height: "auto",
    width: "866px",
    margin: "21px 1px 1px 31px",
    paddingBottom: "18px",
  },
  subTaskroot: {
    paddingTop: "0px",
    paadingBottom: "0px",
    backgroundColor: "inherit",
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
    border: "1px solid #c1c1c1",
  },
  stepLabelstyle: {
    fontSize: "15px",
    fontFamily: "Red Hat Display",
    cursor: "pointer",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
  },
  stepIcon: {
    color: "#0A2472 !important",
  },
  stepertopGrid: {
    display: "flex",
    justifyContent: "space-between",
    // marginTop: "12px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
  },
  steperDescription: {
    fontSize: "13px",
    fontFamily: "Red Hat Display",
    color: "#333333",
  },
  steperLabelStyle: {
    [theme.breakpoints.up("sm")]: {
      fontWeight: "normal",
      color: "#333333",
      display: "flex",
      flexDirection: "row",
      marginTop: 3,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      fontWeight: "normal",
      color: "#333333",
      display: "flex",
      flexDirection: "column",
    },
  },
  stepperStyle: {
    width: "74%",
    paddingLeft: "10px",
    marginLeft: "10px",
    marginBottom: "10px",
    padding: "10px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
  },
  toptextStyle: {
    fontFamily: "Red Hat Display",
    fontWeight: "bold",
    fontSize: 15,
    color: "#0A2472",
  },

  subtaskItem: {
    width: "100px",
  },
  listTextWrap: {
    fontSize: "13px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 130,
  },

  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    borderTop: "1px solid #0000001f",
    paddingTop: "0px",
    paadingBottom: "0px",
  },
  listItemStyle: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  subtaskIdStyle: {
    color: "#0052CC",
    fontFamily: "Red Hat Display",
  },
  avatarStyle: {
    fontSize: "16px",
    width: "35px",
    height: "35px",
  },
  priorityStyle: {
    display: "flex",
    justifyContent: "center",
  },
  imageStyle: {
    width: "20px",
  },
  statusStayle: {
    display: "flex",
    justifyContent: "flex-end",

    fontSize: "13px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 130,
  },
  respMaindiv: {
    width: "70%",
    margin: 8,
    marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 1,
    },
  },
  respMaindiv1: {
    width: "100%",
    // margin: 8,
    // marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 1,
    },
    "& .MuiOutlinedInput-root": {
      width: "100% !important",
    },
  },
  respView: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    "& > div": {
      width: "50%",
      height: "42px",
      borderRadius: 7,
      marginTop: "2px!important",
      marginBottom: "0 !important",
      "& > div": {
        borderRadius: "3px !important",
        "& > input": {
          padding: "8px",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& > div": {
        width: "90%",
      },
    },
  },
  ////// need to need//////
  scrollHeight: {
    height: "64vh",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  scrollhideStyle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      overflowX: "hidden",
    },
  },
  typograyStyle: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 5,
      fontSize: 15,
      width: "100%",
    },
    fontWeight: 500,
    color: "#000",
    fontSize: 14,
    width: "50%",
  },
  respTypographyStyle: {
    fontSize: 16,
    color: "#0A2472",
    fontFamily: "Red Hat Display",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },

  checkRenderTrack: {},
  scrollBarStyle: {
    [theme.breakpoints.down("sm")]: { height: "80vh", overflowX: "hidden" },
    [theme.breakpoints.up("sm")]: {
      height: "71vh",
      overflowX: "hidden",
    },
  },
  respHideDocument: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  respDocument: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  rspnsvCntr: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },

  documentHeightStyle: {
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      height: "522px",
    },
  },
  repiconStyle: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  respBulletStyle: {
    [theme.breakpoints.down("sm")]: {
      // display: "flex",
      // flexDirection: "row",
      display: "none",
    },
  },
  myDatePicker: {
    width: "95%",
    "& div": {
      height: "40px",
      borderRadius: "4px !important",
      borderColor: "#c3D4DA",
    },
  },

  myDatePicker2: {
    width: "100%",
    "& div": {
      height: "40px",
      borderRadius: "4px !important",
      borderColor: "#c3D4DA",
    },
  },
  myDatePicker1: {
    width: "95%",
    float: "right",
    "& div": {
      height: "40px",
      borderRadius: "4px !important",
      borderColor: "#c3D4DA",
    },
  },
  loaderForUpdateLeadData: {
    width: "100% !important",
    display: "flex",
    justifyContent: "center",
    height: "5%",
    "& .loader": {
      height: "60vh",
    },
  },
  nameFieldStyle: { fontSize: 15, marginBottom: 10 },
  dialogContentGrid: { display: "flex", flexDirection: "row" },
  dialogContentstyle: {
    height: "max-content",
    minWidth: 650,
    maxWidth: 637,
    maxHeight: "70vh",
  },
  newStyle: {
    "& .MuiDialog-paperFullWidth": {
      width: "450px !important",
    },
  },
  dialogContentstyleAmount: {
    height: "max-content",
    // minWidth: 650,
    maxWidth: 637,
    maxHeight: "70vh",
  },
  editModalButtonContainer: {
    display: "flex",
    width: "auto",
    justifyContent: "space-around",
    cursor: "pointer",
    // marginTop: 20,
    // marginBottom: 10,
    float: "right",
  },
  editModalButtonContainer1: {
    display: "flex",
    width: "auto",
    justifyContent: "space-evenly",
    cursor: "pointer",
    // marginTop: 20,
    // marginBottom: 10,
    // float: "right",
  },
  editAddButtonContainer: {
    // display: "flex",
    width: "auto",
    // justifyContent: "space-around",
    cursor: "pointer",
    // marginTop: 20,
    // marginBottom: 10,
    float: "left",
    marginLeft: "20px",
  },
  modalButtonStyle: {
    padding: "0px 20px 0px 20px",
    margin: " 0px 0px 0px 0px",
    height: "38px",
    width: "auto",
    backgroundColor: "#0A2472",
    color: "white",
    fontSize: "13px",
    marginLeft: "10px",
    borderRadius: "7px",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  modalPButtonStyle: {
    color: "#bbb",
    fontSize: 14,
    "&:hover": {
      color: "#3772FF",
    },
  },
  respFileFieldStyle: {
    alignItems: "center",
    border: "1px dashed #3772FF",
    borderRadius: 8,
    marginLeft: 12,
    marginTop: 10,
    maxWidth: "89.5% !important",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      maxWidth: "89.5% !important",
    },
  },
  treeStyle: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    backgroundColor: "#FFFFFF",
  },
  liStyle: { paddingLeft: "0px" },

  dottedLinestyle: {
    position: "relative",
    paddingLeft: "2.5em",
    marginLeft: "28px",
    // marginTop: "-12px",
    listStyle: "none",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "10px",
      top: "-12px",
      left: "-3px",
      height: "calc(100% + 0px)",
      borderLeftStyle: "dashed",
      // color: "#6a6868cc"
    },
  },
  licontainer: {
    borderBottom: "0px",
    fontSize: "14px",
    fontFamily: "Red Hat Display",
    position: "relative",
    height: "100",
    backgroundColor: "#ffffff",
  },
  liscrollcontainer: {
    borderBottom: "0px",
    fontSize: "14px",
    fontFamily: "Red Hat Display",
    position: "relative",
    overflowX: "auto",
    height: "100%",
    backgroundColor: "#ffffff",
  },
  tabPanelClass: {
    height: "max-content",
    "& div": {
      paddingRight: "auto",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5,
      },
    },
  },
  dottedstyle: {
    paddingLeft: "1em",
    border: "1px dotted black",
    borderWidth: " 0 0 1px 1px",
  },
  treeLiStyle: {
    position: "relative",
    paddingLeft: "20px",
    paddingTop: "10px",
    marginTop: "25px",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "50px",
      top: "20px",
      left: "-36px",
      borderBottomStyle: "dashed",
    },
  },
  userEmail: {
    fontSize: "15px",
    color: "#000000",
    textDecoration: "none",
  },
  respReportingStyle: {
    minWidth: "100%",
    // border: "1px solid #ced4da",
    [theme.breakpoints.down("sm")]: {
      minWidth: "81%",
      marginTop: "10px",
      // border: "1px solid #ced4da",
    },
  },

  menuItemcolor: {
    color: "black",
    fontWeight: "300 !important",
    "&:hover": {
      backgroundColor: "#e4e4e4 !important",
    },
  },
  navIcons: {
    marginTop: "-6px",
    paddingRight: 3,
  },
  emailColor: {
    color: "#000000",
    textDecoration: "none",
    cursor: "pointer",
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "2%",
  },
  avtarclassStyle: {
    marginLeft: 5,
    height: 70,
    marginTop: "0px",
    fontSize: "45px",
    fontWeight: "500",
    width: 70,
    cursor: "pointer",
    border: "5px solid #ffffff",
    boxShadow: "0 3px 10px rgb(0 0 0 / 60%)",
    backgroundColor: "#ccc",
    [theme.breakpoints.down("sm")]: {
      width: "70px",
      height: "70px",
    },
  },

  // },
  avtarclassStyleOrg: {
    marginLeft: 40,
    height: 90,
    marginTop: "0px",
    fontSize: "45px",
    fontWeight: "500",
    width: 90,
    cursor: "pointer",
    border: "5px solid #ffffff",
    boxShadow: "0 3px 10px rgb(0 0 0 / 60%)",
    backgroundColor: "#ccc",
    [theme.breakpoints.down("sm")]: {
      width: "70px",
      height: "70px",
    },
  },
  topNavRspnsvGrid: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: "100%",
      position: "relative",
    },
  },
  showTopNav: {
    display: "flex !important",
  },
  profileClickOpen: {
    display: "flex",
    alignItems: "center",
    marginTop: "-5px",
    // [theme.breakpoints.down("sm")]: {
    //   display: "block !important",
    // },
  },
  yourProfileShow: {
    background: "#ffffff",
    padding: "10px 5px",
    height: 50,
    margin: "10px 0",
    borderRadius: "5px",
    boxShadow:
      "rgb(50 50 93 / 18%) 0px 2px 5px -1px, rgb(0 0 0 / 0%) 0px 1px 3px -1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    },
  },
  clickProfShowBtn: {
    border: "none",
    padding: 5,
    display: "flex",
    alignItems: "center",
    background: "#223898",
    borderRadius: 5,
    color: "#fff",
    fontWeight: "600",
  },
  avterGrid: {
    height: 60,
    width: 60,
    // backgroundColor: "red",
    borderRadius: "50%",
    position: "absolute",
    top: "20%",
    left: 16,
  },
  cameraIcon: {
    position: "absolute",
    cursor: "pointer",
    top: "60px",
    right: "-30px",
    color: "#faf7f7",
    padding: "5px",
    width: "30px !important",
    height: "30px !important",
    borderRadius: "60px",
    background: "linear-gradient(rgb(10, 36, 114) 0%, rgb(105, 108, 255) 100%)",
  },
  proflBakgrndGrid: {
    height: "50%",
    background: "linear-gradient(180deg, #0A2472 0%, #696CFF 100%)",
  },

  //Responsive for phone//

  [theme.breakpoints.down("sm")]: {
    avterGrid: {
      height: 50,
      width: 50,
      // backgroundColor: "red",
      borderRadius: "50%",
      position: "absolute",
      top: "6%",
      left: 5,
    },
    userNameGrid: {
      width: "100%",
      height: "200px",
      minHeight: "50px",
      marginTop: "10px",
      borderRadius: "5px",
      position: "relative",
    },
    cameraIcon: {
      width: "25px !important",
      height: "25px !important",
      top: 45,
    },
    proflBakgrndGrid: {
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    },
    userNamediv: {
      fontSize: 20,
      background: "#ffffff",
      width: "65%",
      height: 54,
      borderRadius: 5,
      position: "absolute",
      boxShadow: "0 3px 10px rgb(0 0 0 / 20%)",
      right: 10,
      top: 16,
      "& p": {
        marginTop: "0 !important",
        marginLeft: "10px !important",
      },
    },
    userNameP: {
      marginTop: "5px!important",
    },
    userContactGrid: {
      position: "absolute",
      top: "98px",
      height: "100px",
      minHeight: "unset",
      border: "none",
      boxShadow: "none",
    },
    userReportGrid: {
      borderRadius: 5,
    },
    drower: {},

    iconStyle: {
      color: "#fff",
      background: "linear-gradient(180deg, #0A2472 0%, #696CFF 100%)",
      width: "40px !important",
      height: "40px !important",
      padding: "5px",
      borderRadius: "20px",
      border: "2px solid #fff",
      // marginTop: "12px",
      // marginBottom: 12,
    },
  },
}));

export default styles;
