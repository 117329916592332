import {
    call,
    put,
    takeLatest,
    all,
    fork,
    select,
    takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// singleLead Eligibility
function singleLeadEligibilityApi(param) {
    return axios.request({
        method: "post",
        url: `${urls.BASE_URL}${urls.SINGLE_LEAD_ELIGIBILITY_URL}`,
        // headers: {
        //     "Content-Type": "application/json",
        // },
        data:param
    });
}

// singleLead Eligibility action effect
export function* singleLeadEligibilityActionEffect(statusListAction) {
    const { payload, resolve, reject } = statusListAction;

    try {
        //   yield put(toggleBackDrop(true));
        const { data } = yield call(singleLeadEligibilityApi, payload);
        //   yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            return yield put({
                type: actions.SINGLE_LEAD_ELIGIBILITY_SUCCESS,
                data,
            });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
        return yield put({
            type: actions.SINGLE_LEAD_ELIGIBILITY_ERROR,
            e,
        });
    }
}

// Status-List action watcher
export function* singleLeadEligibilityActionWatcher() {
    yield takeLatest(actions.SINGLE_LEAD_ELIGIBILITY, singleLeadEligibilityActionEffect);
}

export default function* rootSaga() {
    yield all([fork(singleLeadEligibilityActionWatcher)]);
}