export const BASE_URL = "https://api.yoindi.com/api/v1";
export const BASE_URL_FILES = "https://document.roopya.com/";
export const SIGN_IN = "/auth/login";
export const SIGN_UP = "/auth/register";
export const RESEND_VERIFICATION_EMAIL = "/auth/resendverify";
export const CREDIT_DETAILS = "/creditscore/crif";
export const CREDIT_DETAILS_DB = "/creditscore/crif/detail";
export const MOBILE_OTP = "/auth/register/verifyOTP";
export const GET_EDIT_BRE_RULE = "/business/get-rule-by-id";
export const DELETE_BRE_RULE = "/business/rule-update-delete";
export const GET_FILTERED_BRE_CUSTOMER_LIST = "/business/rules";
export const LOGOUT_ACTION = "/auth/logout";
export const TASK_LIST = "/task";
export const ADD_TASK = "/task";
export const USER_LIST = "/users";
export const STATUS_LIST = "/status";
export const DELETE_TASK = "/task";
export const UPDATE_TASK = "/task";
export const ADD_MEMBER = "/users";
export const UPDATE_TASK_STATUS = "/task/status";
export const TASK_DETAILS = "/task";
export const DELETE_MEMBER = "/users";
export const FORGOT_PASSWORD = "/resetPassword";
export const FORGOT_PASSWORD_MAIN = "/auth/pwd";
export const ASSIGN_TASK_LIST = "/userdata";
export const CREATE_PASSWORD = "/users";
export const ADD_ROLE = "/role";
export const PRIORITY_LIST = "/priority";
export const USER_DETAILS = "/users";
export const TASK_COMMENTS = "/comment";
export const ROLE_LIST = "/role";
export const UPDATE_ROLE = "/role";
export const ORGANISATION_DETAILS_ACTION = "/auth/organization";
export const TRELLO_BOARD_LIST = "/task/trello";
export const TOKEN_VERIFY = "/accesstoken/verify";
export const EMAIL_VERIFY = "/auth/verifytoken";
export const WORK_SPACE = "/auth/workspace";
export const GENERAL_DATA = "/auth/gk";
export const STAGE_LIST = "/stage";
export const DELETE_ROLE = "/role";
export const UPDATE_MEMBER = "/users";
export const GET_ROLES = "/users/roles";
export const GET_ACCESS_LEVEL = "/access_level";
export const GET_ACCESS_LEVEL_BY_ROLE = "/access_level/role";
export const UPDATE_ACCESS_LEVEL_BY_ROLE = "/access_level/role";
export const OTHER_USER_PROFILE_ACTION = "/users";
export const USER_PROFILE_ACTION = "/users/profile";
export const UPDATE_USER_PROFILE = "/users/profile";
export const UPDATE_LEAD_DATA = "/customer/lead/update";
export const DELETE_EMPLOYMENTLOCATION = "/users/company";
export const DELETE_EMPLOYMENTEDUCATION = "/users/education";
export const UPDATE_ORGANISATION = "/auth/organization";
export const TAGS_SEARCH = "/task/tags?tagsName";
export const DELETE_TASKMEMBER = "/task/assign";
export const DELETE_TASK_DOCUMENT = "/task/documents";
export const DELETE_USER_DOCUMENT = "/users/document";
export const ADD_TASK_MEMBER = "/task/assign";
export const PIPELINE_LIST = "/pipeline";
export const LEADS_LIST = "/lead";
export const PIPELINE_DETAILS = "/pipeline";
export const ADD_SINGLE_LEAD = "/pipeline/addLead";
export const ADD_PIPELINE = "/pipeline";
export const ADD_PIPELINE_PARTNER = "/pipeline/partner/assign";
export const LIST_PIPELINE_PARTNER = "/pipeline/partner/";
export const CHANGE_PASSWORD = "/users/changepassword";
export const RESETPASSWORD_ACTION = "/auth/forget";
export const DELETE_TAGS = "/task/tagsdelete";
export const UPDATE_TASK_DOCUMENT = "/task/documents";
export const LENDERSFIELDS = "/lender/fields";
export const DELETE_PIPELINE = "/pipeline";
export const UPDATE_PIPELINE = "/pipeline";
export const LAST_VIEW_DOCUMENT = "/task/viewed";
export const SHARE_PIPELINE = "/pipeline";
export const ADD_PIPELINE_CRITERIA = "/criteria";
export const CITY_LIST = "/pipeline/citylist";
export const ADD_API_KEY = "/secretkey";
export const SECRET_KEYS = "/secretkey/pipeline";
export const ADD_LEADSOURCE = "/leadsource";
export const UPDATE_SECRETKEY = "/secretkey";
export const DELETE_SECRETKEY = "/secretkey";
export const UTMSOURCE_LIST_BY_SECRETKEY = "/secretkey";
export const DELETE_PIPELINE_PARTNER = "/pipeline/partner";
export const ADD_PIPELINE_LOGO = "/secretkey/logo";
export const PIPELINE_LOGO = "/secretkey/logo";
export const DELETE_PIPELINE_LOGO = "/secretkey/logo";
export const UPDATE_PIPELINE_LOGO = "/secretkey/logo";
export const PIPELINE_LEADS = "/pipeline/leads";
export const DASHBOARD_DATA = "/dashboard";
export const DASHBOARD_DATA_BY_DATE = "/dashboard/date";
export const LEADS_DETAILS_API = "/pipeline/leads/";
export const UPDATE_LEADS_DATA = "/pipeline/leads";
export const MULTIFILTER_LEAD_COUNT =
  "/pipeline/lead_search_with_filter_data_count";
export const SEARCH_FILTER_LEAD_COUNT = "/pipeline/lead_search_data_count";
export const SEARCH_BY_DATE_FILTER_LEAD_COUNT =
  "/pipeline/lead_search_with_date_data_count";
export const LEAD_FILTER_COUNT = "/pipeline/leads_data_count";
export const CUSTOMER_LIST = "/customer";
export const ADD_PIPELINE_USER = "/pipeline/user";
export const PIPELINE_SINGLE_USER = "/pipeline/user/";
export const PIPELINE_ALL_USER = "/pipeline/users/";
export const DELETE_PIPELINE_USER = "/pipeline/user/";
export const UPLOAD_EXCEL_DOCUMENT = "/pipeline/leads/addexcel";
export const ADD_STAGE_TEMPLATE_URL = "/template/stage";
export const GET_SINGLE_STAGE_TEMPLATE_URL = "/template/stage/";
export const GET_ALL_STAGE_TEMPLATE_URL = "/template/stage";
export const EDIT_STAGE_TEMPLATE_URL = "/template/stage/";
export const DELETE_STAGE_TEMPLATE_URL = "/template/stage/";
export const ADD_BUSINESS_RULE_URL = "/business/add-rules";
export const GET_BUSINESS_RULE_URL = "/business";
export const GET_USER_APPLICATION_DASHBOARD = "/dashboard/application";
export const GET_LENDER_DASHBOARD = "/dashboard/lenders";
export const GET_PARTNER_DASHBOARD = "/dashboard/partner";
export const GET_BUSINESS_RULE_LIST = "/business/add-rules";
export const DASHBOARD_LENDER_DETAILS = "/dashboard/lenders/";
export const MARKET_PLACE_DOMAIN_URL = "/company/list";
export const SAVE_MARKET_PLACE_DOMAIN_URL = "/pipeline/";
export const MARKET_PLACE_DOMAIN_LIST_URL = "/company/transfer/lead/";
export const SINGLE_LEAD_ELIGIBILITY_URL = "/bank/singleLead/eligibility";
export const AUTO_LOGIN_LIST_URL = "/bank/autologin/details/";
export const ALL_BANK_CREDENTIAL_LIST_URL = "/bank/credential";
export const ADD_BANK_AUTO_LOGIN_URL = "/bank/autologin/add";
export const ENABLE_DISABLE_AUTO_LOGIN = "/bank/autologin/";
export const TIMELINE_DATA_URL = "/bank/timeline/status";
export const GLOBAL_NOTES_DATA_LIST = "/pipeline/lead/notes/";
export const ADD_GLOBAL_NOTES_DATA_LIST = "/pipeline/lead/notes";
export const AUTO_LOGIN_CRON_REFRESH = "/bank/autologin";
export const CRON_GET_STATUS_URL = "/bank/cronStatus/get";
export const CRON_ADD_STATUS_URL = "/bank/cronStatus/add";
export const CRON_UPDATE_STATUS_URL = "/bank/cronStatus/update";
export const ACTIVE_BANK_LIST_LEADS_URL = "/pipeline/leads/logs";
export const ACTIVE_BANK_LIST_URL = "/bank/credential";
export const SINGLE_LEAD_BANK_STATUS = "/bank/singleLead/status";
export const TIMELINE_BANK_STATUS_URL = "/bank/timeline/status";
export const BANK_LIST_URL = "/bank/list";
export const BANK_CREDENTIAL_URL = "/bank/credential";
export const CUSTOMER_DOCUMENT_DELETE_URL = "/customer/document/delete";
export const CUSTOMER_DOCUMENT_VERIFY_URL = "/customer/document/verify/";
export const CUSTOMER_DOCUMENT_UPDATE_URL = "/customer/document/update/";
export const SINGLE_LEAD_BANK_PUSH = "/bank/singleLead/push";
export const ASSIGN_PIPELINE_LIST = "/pipeline/assign/leads";
export const BULK_LEAD_PUSH_URL = "/bank/bulkLead/push";
export const PIPELINE_ACCESS_LEAD = "/pipeline/access/lead";
export const PIPELINE_ARCHIVE_DELETE = "/pipeline";
export const GET_ACCEPTED_AMOUNT = "/bank/lead/approved/amount";
export const UPDATE_LEAD_FAVORITE_LEAD_STATUS_URL =
  "/pipeline/leads/is_fivorate";
export const UPDATE_LEAD_SEEN_STATUS_URL = "/pipeline/leads/is_seen";
export const NEXT_STAGE_URL = "/bank/lead/status/update";
export const LEAD_STAGE_LIST = "/pipeline/stage_details/";
export const SEARCH_PIPLELINE_LEADS = "/pipeline/lead_search/";
export const SEARCH_LEADS_BY_DATE = "/pipeline/lead_search_with_date";
export const MULTI_FILTER_LEAD_LIST = "/pipeline/lead_search_with_filter";
