import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useLayoutEffect,
} from "react";
import useStyles from "../BusinessRulesDetails.style";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TextField from "@material-ui/core/TextField";
import Loader from "../../../componennts/Backdrop/Backdrop";
import { updateAlertMsg } from "../../../store/actions/commonAction";
import * as urls from "../../../utils/urls";
import EditIcon from "@mui/icons-material/Edit";
import Multiselect from "multiselect-react-dropdown";
// import Select from "react-select";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { getBusinessRule } from "../../../store/actions/getBusinessData";
import { getBusinessRuleEdit } from "../../../store/actions/getBusinessRuleEdit";
// import { getBusinessEditRule } from "../../../store/actions/getBusinessRule";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getBusinessRuleSuccess } from "../../../store/actions/getBusinessData";

import { v4 } from "uuid";
import { add } from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  addBusinessRule,
  addBusinessRuleSuccess,
} from "../../../store/actions/addbusinessRule";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import BusinessEditSingleRule from "./BusinessEditSingleRule";
import BusinessEditGroup from "./BusinessEditGroup";
import useModalStyles from "../../../pages/UserProfile/UserProfile.style";
import { useHistory, useLocation, useParams } from "react-router";
import axios from "axios";
import { getCookie } from "../../../utils/common";
import LayoutContext from "../../../Context/LayoutContext";
import ExampleDialogComponent from "../UpdatedLoader";

const BusinessEditRulesDetails = (props) => {
  const layoutContext = useContext(LayoutContext);
  const location = useLocation();
  let businessRule = "";
  let businessDataEdit = "";
  let id = "";
  const [dataEdit, setDataEdit] = useState({});
  // if (location && location.state && location.state.businessRuleName) {
  // businessRule = location.state.businessRuleName;
  businessRule = localStorage.getItem("ruleName");
  // }
  // if (location && location.state && location.state.businessData) {
  //   businessDataEdit = location.state.businessData;
  // }
  // if (location && location.state && location.state.id) {
  //   id = location.state.id;
  // }
  const getParams = useParams();

  useEffect(() => {
    props.getBusinessRule();
  }, []);
  useEffect(() => {
    console.log("inside useEffect");
    props.getBusinessRuleEdit({ id: getParams.id });
  }, [getParams.id]);
  console.log(
    "businessName",
    props.getBusinessEditRule && props.getBusinessEditRule.data
  );
  useEffect(() => {
    console.log("inside useEffect", props.getBusinessEditRule);
    if (props.getBusinessEditRule) {
      if (Object.keys(props.getBusinessEditRule).length === 0) {
        console.log();
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
    }
  }, [props.getBusinessEditRule]);
  const { getBusinessRuleData = {}, addBusinessRuleData = {} } = props;
  const { data: getBusinessData = [] } = getBusinessRuleData;
  // console.log("props=>", props);

  useLayoutEffect(() => {
    layoutContext.setDisabledTopNav(false);
    layoutContext.setDisabledBottomNav(true);
    // props.getBusinessEditRule();
  }, []);

  const history = useHistory();
  const [and, setAnd] = useState(false);
  const [or, setOr] = useState(false);
  const [dummyData, setDummyData] = useState(
    //  props.getBusinessEditRule
    businessDataEdit
    //   [
    //   {
    //     id: "9d8b1f16-f153-4066-bf27-c7a2c0180548",
    //     type: "group",
    //     condition: "[Op.and]",
    //     ruleList: [
    //       {
    //         id: "6c7cdae3-d6c2-4c64-8af7-80eaee19052c",
    //         fieldName: "your_net_monthly_income",
    //         value: 70000,
    //         action: "[Op.eq]",
    //         type: "number",
    //         operatorList: [
    //           {
    //             label: "Equal",
    //             value: "[Op.eq]",
    //           },
    //           {
    //             label: "Greater Than",
    //             value: "[Op.gt]",
    //           },
    //           {
    //             label: "Less Than",
    //             value: "[Op.lt]",
    //           },
    //           {
    //             label: "Not Equal",
    //             value: "[Op.ne]",
    //           },
    //           {
    //             label: "Greater Then Equal",
    //             value: "[Op.gte]",
    //           },
    //           {
    //             label: "Less Than Equal",
    //             value: "[Op.lte]",
    //           },
    //         ],
    //         input_value: [""],
    //       },
    //     ],
    //   },
    //   {
    //     id: "aa524bd8-baa4-48ec-ac9d-cf0acf09042c",
    //     type: "group",
    //     condition: "[Op.or]",
    //     ruleList: [
    //       {
    //         id: "0d0e4bca-4be5-4539-9367-3d248e0d46be",
    //         fieldName: "purpose",
    //         value: "Personal Loan",
    //         action: "[Op.eq]",
    //         type: "select",
    //         operatorList: [
    //           {
    //             label: "Equal",
    //             value: "[Op.eq]",
    //           },
    //           {
    //             label: "Not Equal",
    //             value: "[Op.ne]",
    //           },
    //         ],
    //         input_value: ["Personal Loan", "Credit Card", "Consumer Loan"],
    //       },
    //     ],
    //   },
    //   {
    //     id: "df384968-b497-462f-872f-bcc4542d81cb",
    //     type: "group",
    //     condition: "[Op.and]",
    //     ruleList: [
    //       {
    //         id: "b9249eb1-16e3-4a55-881d-3b990fbbcb74",
    //         fieldName: "perform_cdn_score",
    //         value: 500,
    //         action: "[Op.gt]",
    //         type: "number",
    //         operatorList: [
    //           {
    //             label: "Equal",
    //             value: "[Op.eq]",
    //           },
    //           {
    //             label: "Greater Than",
    //             value: "[Op.gt]",
    //           },
    //           {
    //             label: "Less Then",
    //             value: "[Op.lt]",
    //           },
    //           {
    //             label: "Not Equal",
    //             value: "[Op.ne]",
    //           },
    //           {
    //             label: "Greater Than Equal",
    //             value: "[Op.gte]",
    //           },
    //           {
    //             label: "Less Than Equal",
    //             value: "[Op.lte]",
    //           },
    //         ],
    //         input_value: [""],
    //       },
    //       {
    //         id: "04d42711-4f42-4418-87d4-c766ab1abc17",
    //         fieldName: "perform_cdn_score",
    //         value: 900,
    //         action: "[Op.lt]",
    //         type: "number",
    //         input_value: [""],
    //         operatorList: [
    //           {
    //             label: "Equal",
    //             value: "[Op.eq]",
    //           },
    //           {
    //             label: "Greater Than",
    //             value: "[Op.gt]",
    //           },
    //           {
    //             label: "Less Then",
    //             value: "[Op.lt]",
    //           },
    //           {
    //             label: "Not Equal",
    //             value: "[Op.ne]",
    //           },
    //           {
    //             label: "Greater Than Equal",
    //             value: "[Op.gte]",
    //           },
    //           {
    //             label: "Less Than Equal",
    //             value: "[Op.lte]",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // ]
  );

  const classes = useStyles();
  const [businessRuleName, setBusinessRuleName] = useState(businessRule);
  const domain = getCookie("domain");
  const access_token = getCookie("token");
  const [showLabel, setShowLabel] = useState(true);
  const classesModal = useModalStyles();
  const [dummyList, setDummyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getBusinesData, setBusinessGetData] = useState([]);

  const [firstGroup, setFirstGroup] = useState({
    id: v4(),
    type: "group",
    condition: "",
    ruleList: [],
  });
  const [firstCon, setFirstCon] = useState("");

  const [singleRule, setSingleRule] = useState({
    ruleList:
      props.getBusinessEditRule &&
      props.getBusinessEditRule.data &&
      props.getBusinessEditRule.data.length > 0 &&
      props.getBusinessEditRule.data[0].ruleList,
  });

  let newDummy =
    props.getBusinessEditRule &&
    props.getBusinessEditRule.data &&
    props.getBusinessEditRule.data.length > 1
      ? props.getBusinessEditRule.data.slice(1)
      : [
          {
            id: v4(),
            type: "group",
            condition: "",
            ruleList: [
              {
                id: v4(),
                fieldName: "",
                value: "",
                action: "",
                operatorList: [],
                type: "",
                input_value: [],
              },
            ],
          },
        ];
  console.log("newDummy", newDummy);
  const [singleGroup, setSingleGroup] = useState({
    groupList: [],
  });
  useEffect(() => {
    setSingleGroup({
      ...singleGroup,
      groupList:
        props.getBusinessEditRule &&
        props.getBusinessEditRule.data &&
        props.getBusinessEditRule.data.length > 1
          ? props.getBusinessEditRule.data.slice(1)
          : [
              // {
              //   id: v4(),
              //   type: "group",
              //   condition: "",
              //   ruleList: [
              //     {
              //       id: v4(),
              //       fieldName: "",
              //       value: "",
              //       action: "",
              //       operatorList: [],
              //       type: "",
              //       input_value: [],
              //     },
              //   ],
              // },
            ],
    });
  }, [
    props.getBusinessEditRule &&
      props.getBusinessEditRule.data &&
      props.getBusinessEditRule.data.length > 1,
  ]);
  // console.log("singleGroup=>", singleGroup);
  useEffect(() => {
    setSingleRule({
      ...singleRule,
      ruleList:
        props.getBusinessEditRule &&
        props.getBusinessEditRule.data &&
        props.getBusinessEditRule.data.length > 0 &&
        props.getBusinessEditRule.data[0].ruleList,
    });

    if (
      props.getBusinessEditRule &&
      props.getBusinessEditRule.data &&
      props.getBusinessEditRule.data.length > 0 &&
      props.getBusinessEditRule.data[0].condition === "[Op.and]"
    ) {
      setOr(true);
      setAnd(false);
      setFirstGroup({
        ...firstGroup,
        condition: "[Op.and]",
        ruleList:
          props.getBusinessEditRule &&
          props.getBusinessEditRule.data &&
          props.getBusinessEditRule.data.length > 0 &&
          props.getBusinessEditRule.data[0].ruleList,
      });
    } else if (
      props.getBusinessEditRule &&
      props.getBusinessEditRule.data &&
      props.getBusinessEditRule.data.length > 0 &&
      props.getBusinessEditRule.data[0].condition === "[Op.or]"
    ) {
      setAnd(true);
      setOr(false);
      setFirstGroup({
        ...firstGroup,
        condition: "[Op.or]",
        ruleList:
          props.getBusinessEditRule &&
          props.getBusinessEditRule.data &&
          props.getBusinessEditRule.data.length > 0 &&
          props.getBusinessEditRule.data[0].ruleList,
      });
    }
  }, [getBusinessData.length]);
  console.log("singleRule", singleRule);

  const ogBageToUrl = () => {
    props.history.goBack();
  };

  const OnConjunctionClick = (type) => {
    if (type === "[Op.or]") {
      setAnd(true);
      setOr(false);
    }
    if (type === "[Op.and]") {
      setOr(true);
      setAnd(false);
    }
    setFirstCon(type);
    setFirstGroup({ ...firstGroup, condition: type });
  };
  const [addedRuleClicked, setAddedRuleClicked] = React.useState(false);
  const addSingleRule = (singleRule) => {
    setAddedRuleClicked(true);
    setSingleRule({
      ruleList: [
        ...singleRule.ruleList,
        {
          id: v4(),
          fieldName: "",
          value: "",
          action: "",
          operatorList: [],
          type: "",
          input_value: [],
        },
      ],
    });
  };

  const addSingleGroup = () => {
    setSingleGroup({
      groupList: [
        ...singleGroup.groupList,
        {
          id: v4(),
          type: "group",
          condition: "",
          ruleList: [
            {
              id: v4(),
              fieldName: "",
              value: "",
              action: "",
              operatorList: [],
              type: "",
              input_value: [],
            },
          ],
        },
      ],
    });
  };

  const getNewRuleList = (newRuleList, groupId) => {
    let newList = singleGroup.groupList;
    let objIndex = newList.findIndex((obj) => obj.id === groupId);
    newList[objIndex].ruleList = newRuleList;
    setSingleGroup({ groupList: [...newList] });
  };

  const getUpdatedRuleList = (rule, groupId, type) => {
    let gpList = singleGroup.groupList;
    let objGpIndex = gpList.findIndex((obj) => obj.id === groupId);
    let objRuleIndex = gpList[objGpIndex].ruleList.findIndex(
      (obj) => obj === rule[0]
    );
    gpList[objGpIndex].ruleList[objRuleIndex].type = type;

    setSingleGroup({ groupList: [...gpList] });
  };

  const getUpdatedOperatorList = (rule, groupId, operatorList) => {
    let gpList = singleGroup.groupList;
    let objGpIndex = gpList.findIndex((obj) => obj.id === groupId);
    let objRuleIndex = gpList[objGpIndex].ruleList.findIndex(
      (obj) => obj === rule[0]
    );
    gpList[objGpIndex].ruleList[objRuleIndex].operatorList = operatorList;
    setSingleGroup({ groupList: [...gpList] });
  };

  const getUpdateInputList = (rule, groupId, inputList) => {
    let gpList = singleGroup.groupList;
    let objGpIndex = gpList.findIndex((obj) => obj.id === groupId);
    let objRuleIndex = gpList[objGpIndex].ruleList.findIndex(
      (obj) => obj === rule[0]
    );
    gpList[objGpIndex].ruleList[objRuleIndex].input_value = inputList;

    setSingleGroup({ groupList: [...gpList] });
  };

  const getUpdateFieldName = (rule, groupId, fieldName) => {
    let gpList = singleGroup.groupList;
    let objGpIndex = gpList.findIndex((obj) => obj.id === groupId);
    let objRuleIndex = gpList[objGpIndex].ruleList.findIndex(
      (obj) => obj === rule[0]
    );
    gpList[objGpIndex].ruleList[objRuleIndex].fieldName = fieldName;
    setSingleGroup({ groupList: [...gpList] });
  };

  const getUpdateAction = (rule, groupId, action) => {
    let gpList = singleGroup.groupList;
    let objGpIndex = gpList.findIndex((obj) => obj.id === groupId);
    let objRuleIndex = gpList[objGpIndex].ruleList.findIndex(
      (obj) => obj === rule[0]
    );
    gpList[objGpIndex].ruleList[objRuleIndex].action = action;
    setSingleGroup({ groupList: [...gpList] });
  };
  const getUpdateValue = (rule, groupId, value) => {
    let gpList = singleGroup.groupList;
    let objGpIndex = gpList.findIndex((obj) => obj.id === groupId);
    let objRuleIndex = gpList[objGpIndex].ruleList.findIndex(
      (obj) => obj === rule[0]
    );
    gpList[objGpIndex].ruleList[objRuleIndex].value = value;
    setSingleGroup({ groupList: [...gpList] });
  };

  const getConditionValue = (condition, groupId) => {
    let newList = singleGroup.groupList;
    let objIndex = newList.findIndex((obj) => obj.id === groupId);
    newList[objIndex].condition = condition;
    setSingleGroup({ groupList: [...newList] });
  };

  const onDelete = (value) => {
    const newList = singleRule.ruleList.filter((data) => data != value);
    setSingleRule({
      ruleList: newList,
    });
  };

  const onDeleteGroup = (value) => {
    const newGroupList = singleGroup.groupList.filter((data) => data != value);
    setSingleGroup({
      groupList: newGroupList,
    });
  };

  const getType = (rule, type) => {
    let newRuleList = singleRule.ruleList;
    let objIndex = newRuleList.findIndex((obj) => obj.id === rule.id);
    newRuleList[objIndex].type = type;
    setSingleRule({ ruleList: [...newRuleList] });
  };
  const getOperatorsList = (rule, operatorList) => {
    let newRuleList = singleRule.ruleList;
    let objIndex = newRuleList.findIndex((obj) => obj.id === rule.id);
    newRuleList[objIndex].operatorList = operatorList;
    setSingleRule({ ruleList: [...newRuleList] });
  };
  const getInputList = (rule, inputList) => {
    let inputRuleList = singleRule.ruleList;
    let objIndex = inputRuleList.findIndex((obj) => obj.id === rule.id);
    inputRuleList[objIndex].input_value = inputList;
    setSingleRule({ ruleList: [...inputRuleList] });
  };
  const getFieldName = (rule, fieldName) => {
    let inputRuleList = singleRule.ruleList;
    let objIndex = inputRuleList.findIndex((obj) => obj.id === rule.id);
    inputRuleList[objIndex].fieldName = fieldName;
    setSingleRule({ ruleList: [...inputRuleList] });
    setFirstGroup({ ...firstGroup, ruleList: singleRule.ruleList });
  };
  const getSelectedAction = (rule, action) => {
    console.log("get selected action=>", rule, action);
    let inputRuleList = singleRule.ruleList;
    let objIndex = inputRuleList.findIndex((obj) => obj.id === rule.id);
    inputRuleList[objIndex].action = action;
    setSingleRule({ ruleList: [...inputRuleList] });
    setFirstGroup({ ...firstGroup, ruleList: singleRule.ruleList });
  };
  const getSelectedValue = (rule, value) => {
    let inputRuleList = singleRule.ruleList;
    let objIndex = inputRuleList.findIndex((obj) => obj.id === rule.id);
    inputRuleList[objIndex].value = value;
    setSingleRule({ ruleList: [...inputRuleList] });
    setFirstGroup({ ...firstGroup, ruleList: singleRule.ruleList });
  };
  // const [validation, setValidation] = useState(false);
  // useEffect(() => {
  //   setDummyList(singleGroup.groupList);
  // }, [singleGroup.groupList]);
  useEffect(() => {
    console.log("new singleGroup=>", singleGroup);
    setDataEdit(singleGroup);
  }, [singleGroup]);
  console.log("data=>", dataEdit);

  const { ruleList } = singleRule;
  const { groupList } = singleGroup;
  console.log("before submit singleGroup=>", groupList);
  const submitRule = () => {
    setIsLoading(true);
    // console.log("GroupList=>", list);
    let grp = [];
    if (
      props.getBusinessEditRule &&
      props.getBusinessEditRule.data &&
      props.getBusinessEditRule.data.length > 0
    ) {
      let reqlist = props.getBusinessEditRule.data[0];
      grp = [reqlist, ...groupList];
    }
    // console.log("after submit singleGroup=>", grp);
    let groupAllList = grp;
    let newObj = {};
    // console.log("firstGroup=>", firstGroup);
    if (groupAllList[0].ruleList[0].action.length === 0) {
      console.log("if not group added");
      newObj = {
        id: getParams.id,
        businessRuleName: businessRuleName,
        query_data: [firstGroup],
      };
    } else {
      // dummyList.unshift(firstGroup);
      // let dummyList = [firstGroup, ...groupAllList];
      // console.log("second firstGroup=>", firstGroup);
      // groupAllList.unshift(firstGroup);
      // let reqList = [firstGroup, ...groupAllList];
      let reqList = [firstGroup].concat(groupAllList.slice(1));
      // console.log("if group added", reqList);
      newObj = {
        id: getParams.id,
        businessRuleName: businessRuleName,
        query_data: reqList,
      };
    }
    let objList = {
      businessRuleName: businessRuleName,
      query_data: groupAllList,
    };
    console.log("Required payload>", newObj);
    const headersCommonNew = {
      domain_name: domain === "lenders" ? "" : domain,
      apiKey: "D2BDF6499CD925AB4DAF513BE589F",
      apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
      Authorization: `Bearer ${access_token || ""}`,
      "Content-Type": "application/json",
    };
    var config7 = {
      method: "put",
      url: `${urls.BASE_URL}${urls.DELETE_BRE_RULE}`,
      headers: headersCommonNew,
      data: newObj,
    };
    // groupAllList.shift();
    axios(config7)
      .then(function (response) {
        if (response.data.statusCode === 200) {
          setIsLoading(false);
          console.log("response=>", response);
          props.updateAlertMsg({
            message: response.data.message,
            messageType: "success",
          });
          props.history.push("/business_rules");
        }
      })
      .catch(function (err) {
        setIsLoading(false);
        props.updateAlertMsg({
          message: err.response.data.message,
          messageType: "error",
        });
        props.history.push("/business_rules");
      });
  };

  console.log("all data=>", singleRule, groupList);
  const handleBusinessRuleName = (e) => {
    const { value } = e.target;
    if (value.length > 0) {
      setBusinessRuleName(value);
    } else {
      setBusinessRuleName("Business Rule Details");
    }
  };

  const handleBlur = (e) => {
    setShowLabel(true);
  };
  const handleOnClick = (e) => {
    setShowLabel(false);
  };
  const breadcrumbs = [
    <Link key="1" underline="none" onClick={() => ogBageToUrl()}>
      <span className={classes.breadscrumStyle}> HOME</span>
    </Link>,
    <Link
      key="2"
      underline="none"
      onClick={() => history.push({ pathname: "/business_rules" })}
    >
      <span className={classes.breadscrumStyle}> Business Rule List</span>
    </Link>,
    <Link key="2" underline="none">
      <span className={classes.breadscrumStyle}> Business Rule Edit</span>
    </Link>,
    <React.Fragment>
      {showLabel ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ marginRight: "8px" }}>
            {businessRuleName ? businessRuleName : "Business Rule Details"}
          </span>
          <EditIcon onClick={handleOnClick} style={{ cursor: "pointer" }} />
        </div>
      ) : (
        <TextField
          id="standard-basic"
          label=""
          variant="standard"
          placeholder={
            businessRuleName ? businessRuleName : "Business Rule Details"
          }
          onChange={(e) => handleBusinessRuleName(e)}
          onBlur={handleBlur}
        />
      )}
    </React.Fragment>,
  ];
  return (
    <div className={classes.businessRulesDetails}>
      <div className={classes.hdrBusiness}>
        <h6
          style={{
            fontSize: 16,
            marginBottom: 0,
            fontWeight: "600",
            color: "#6A6767",
            marginLeft: "20px",
          }}
        >
          <Breadcrumbs
            separator={<ArrowForwardIosIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </h6>
      </div>
      <div className="container">
        <div className="row"></div>

        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <div className="container" style={{ position: "relative" }}>
            {isLoading && (
              <div
                className={classesModal.loaderForUpdateLeadData}
                style={{
                  zIndex: 1000,
                  position: "fixed",
                  left: "0px",
                  top: "50%",
                }}
              >
                {/* <Loader openBackdrop={true} /> */}
                {/* <CircularProgress /> */}
                <ExampleDialogComponent />
              </div>
            )}
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: "center",
                zIndex: 1,
                position: "absolute",
              }}
            >
              <Grid item xs={10}>
                <React.Fragment>
                  <Box
                    className={
                      isLoading ? classes.groupBoxBlur : classes.groupBox
                    }
                  >
                    {/* {isLoading ? (
                  <div className={classesModal.loaderForUpdateLeadData}>
                    <Loader openBackdrop={true} />
                  </div>
                ) : ( */}

                    {/* <Grid
                  container
                  spacing={2}
                  style={{
                    justifyContent: "center",
                    zIndex: 1,
                    position: "absolute",
                  }}
                >
                  <Grid item xs={10}> */}
                    <React.Fragment>
                      <div className={classes.displayInRow}>
                        <ButtonGroup
                          disableElevation
                          variant="contained"
                          aria-label="Disabled elevation buttons"
                        >
                          <Button
                            onClick={() => OnConjunctionClick("[Op.and]")}
                            className={`${classes.AND} ${
                              or && classes.selOR
                              // : classes.selAND
                            }`}
                          >
                            AND
                          </Button>
                          <Button
                            onClick={() => OnConjunctionClick("[Op.or]")}
                            className={`${classes.AND} ${
                              and &&
                              //  classes.selOR :
                              classes.selAND
                            }`}
                          >
                            OR
                          </Button>
                        </ButtonGroup>
                        <div>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="text"
                              onClick={() => addSingleRule(singleRule)}
                              className={classesModal.modalButtonStyle}
                            >
                              + Rule
                            </Button>
                            <Button
                              variant="text"
                              onClick={addSingleGroup}
                              className={classesModal.modalButtonStyle}
                            >
                              + Group
                            </Button>
                          </Stack>
                        </div>
                      </div>
                      <div>
                        {ruleList && ruleList.length > 0 && (
                          <BusinessEditSingleRule
                            ruleList={ruleList}
                            onDelete={onDelete}
                            getBusinessRuleData={getBusinessRuleData}
                            getType={getType}
                            getOperatorsList={getOperatorsList}
                            getInputList={getInputList}
                            getFieldName={getFieldName}
                            getSelectedAction={getSelectedAction}
                            getSelectedValue={getSelectedValue}
                          />
                        )}
                      </div>
                      <div>
                        {groupList && groupList.length > 0 && (
                          <BusinessEditGroup
                            groupList={groupList}
                            onDeleteGroup={onDeleteGroup}
                            getNewRuleList={getNewRuleList}
                            getUpdatedRuleList={getUpdatedRuleList}
                            getUpdatedOperatorList={getUpdatedOperatorList}
                            getUpdateInputList={getUpdateInputList}
                            getBusinessRuleData={getBusinessRuleData}
                            getConditionValue={getConditionValue}
                            getUpdateFieldName={getUpdateFieldName}
                            getUpdateAction={getUpdateAction}
                            getUpdateValue={getUpdateValue}
                          />
                        )}
                      </div>
                    </React.Fragment>
                    {/* </Grid>
                </Grid> */}
                    {/* )} */}
                  </Box>
                </React.Fragment>

                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  {!isLoading && (
                    <Button
                      variant="contained"
                      onClick={submitRule}
                      // disabled={true}
                      className={classesModal.modalButtonStyle}
                    >
                      Update Rule
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  getBusinessRuleData: state.businessDataReducer.getBusinessData,
  getBusinessEditRule: state.getBusinessEditDataReducer.getBusinessRuleEdit,
  addBusinessRuleData: state.businessDataReducer.addBusiness,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBusinessRule,
      updateAlertMsg,
      // getBusinessEditRule,
      getBusinessRuleEdit,
      getBusinessRuleSuccess,
      addBusinessRule,
      addBusinessRuleSuccess,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessEditRulesDetails);
