import {
  SEARCH_PIPELINE_LEADS,
  SEARCH_PIPELINE_LEADS_SUCCESS,
  SEARCH_PIPELINE_LEADS_FAILED,
} from "../actions/types";

const initialState = {
  searchPipelineLeads: [],
};

const searchPipelineLeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PIPELINE_LEADS:
      return {
        ...state,
        searchPipelineLeads: action.data,
      };
    case SEARCH_PIPELINE_LEADS_SUCCESS:
      return {
        ...state,
        searchPipelineLeads: action.data,
      };
    case SEARCH_PIPELINE_LEADS_FAILED:
      return {
        ...state,
        searchPipelineLeads: action.data,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
export default searchPipelineLeadsReducer;
