import * as actions from "../actions/types";

const initialState = {
  getBusinessRuleEdit: [],
  getBusinessEditSuccess: [],
  getBusinessEditErr: [],
};

const getBusinessEditDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_BUSINESS_RULE_EDIT:
      return {
        ...state,
        getBusinessRuleEdit: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    case actions.GET_BUSINESS_RULE_EDIT_SUCCESS:
      return {
        ...state,
        getBusinessRuleEdit: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    case actions.GET_BUSINESS_RULE_EDIT_ERROR:
      return {
        ...state,
        getBusinessRuleEdit: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    default: {
      return state;
    }
  }
};
export default getBusinessEditDataReducer;
