import * as actions from "../actions/types";

const initialState = {
  activeBankListData: [],
};

const activeBanklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ACTIVE_BANK_LIST_ACTION:
      return {
        ...state,
        activeBankListData: action.data,
      };
    case actions.ACTIVE_BANK_LIST_ACTION_SUCCESS:
      return {
        ...state,
        activeBankListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default activeBanklistReducer;
