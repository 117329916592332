import { alpha, makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  root: {},
  reactFormBuilder: {
    marginTop: 42,
    paddingLeft: "20px",
  } 
}));

export default styles;
