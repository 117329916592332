import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getCookie, isEmpty } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// Update task status API

function updateTaskStatusApi(params) {
  let id = params.id;
  let data = { status_id: params.status_id, percentage: params.percentage };
  return axios.request({
    method: "put",
    url: `${urls.BASE_URL}${urls.UPDATE_TASK_STATUS}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: data,
  });
}

export function* updateTaskStatusActionEffect(updateTaskStatusAction) {
  const { payload, resolve, reject } = updateTaskStatusAction;

  try {
    yield;
    // put(toggleBackDrop(true));
    const { data } = yield call(updateTaskStatusApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update task status action watcher
export function* updateTaskActionStatusWatcher() {
  yield takeLatest(actions.UPDATE_TASK_STATUS, updateTaskStatusActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateTaskActionStatusWatcher)]);
}
