import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";
// Add member API
function autoLoginListApi(params) {
    console.log("autoLoginListApi", params)
    // const domain = getCookie("domain");
    return axios.request({
        method: "get",
        url: `${urls.BASE_URL}${urls.AUTO_LOGIN_LIST_URL}${params}`,
        // headers: {
        //     "Content-Type": "application/json",
        // },
    });
}

// Add member action effect
export function* autoLoginListActionEffect(autoLoginList) {
    const { payload, resolve, reject } = autoLoginList;
    try {
        // yield put(toggleBackDrop(true));
        const { data } = yield call(autoLoginListApi, payload);
        // yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            return yield put({
                type: actions.AUTO_LOGIN_LIST_SUCCESS,
                data,
            });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
            return yield put({
                type: actions.AUTO_LOGIN_LIST_ERROR,
                data,
            });
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}

// Sign-in action watcher
export function* autoLoginListActionWatcher() {
    yield takeLatest(actions.AUTO_LOGIN_LIST, autoLoginListActionEffect);
}

export default function* rootSaga() {
    yield all([fork(autoLoginListActionWatcher)]);
}
