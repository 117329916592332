import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update task API
function pipelinePartnerAssignApi(params) {
  //   console.log(params);
  //   let shareData = { share: params.shareData };
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.ADD_PIPELINE_PARTNER}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Update task action effect
export function* pipelinePartnerAssignActionEffect(pipelineShareAction) {
  const { payload, resolve, reject } = pipelineShareAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(pipelinePartnerAssignApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update task action watcher
export function* pipelinePartnerAssignActionWatcher() {
  yield takeLatest(
    actions.PIPELINE_PARTNER_ASSIGN,
    pipelinePartnerAssignActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(pipelinePartnerAssignActionWatcher)]);
}
