import * as actions from "../actions/types";

const initialState = {
  getAccessLevelData: [],
};

const accessLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ACCESS_DATA_ACTION:
      return {
        ...state,
        getAccessLevelData: action.data,
      };
    case actions.ACCESS_DATA_ACTION_SUCCESS:
      return {
        ...state,
        getAccessLevelData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default accessLevelReducer;
