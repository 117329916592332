import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Create password API
function cronAddStatusApi(params) {
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.CRON_ADD_STATUS_URL}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Create password action effect
export function* cronAddStatusActionEffect(cronAddStatusApiAction) {
  const { payload, resolve, reject } = cronAddStatusApiAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(cronAddStatusApi, payload);
    // yield put(toggleBackDrop(false));
    // console.log("data", data.token);
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.CRON_ADD_STATUS_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.CRON_ADD_STATUS_ERROR,
      e,
    });
  }
}

// Create password action watcher
export function* cronAddStatusActionWatcher() {
  yield takeLatest(actions.CRON_ADD_STATUS, cronAddStatusActionEffect);
}

export default function* rootSaga() {
  yield all([fork(cronAddStatusActionWatcher)]);
}
