import { getCookie } from "./common";
import axios from "axios";

const domain = window.location.host.split(".")[0];
let instance = null;
let Headers = {
  "Content-Type": "application/json",
  domain_name: "",
  apiKey: "D2BDF6499CD925AB4DAF513BE589F",
  apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
};

if (window.location.host.includes("localhost")) {
  if (window.location.hash.includes("/login"))
    instance = axios.create({ timeout: 1000 * 300, headers: { ...Headers } });
  else
    instance = axios.create({
      timeout: 1000 * 300,
      headers: { ...Headers, domain_name: "admin" },
    });
} else {
  instance = axios.create({
    timeout: 1000 * 300,
    headers: { ...Headers, domain_name: domain === "lenders" ? "" : domain },
  });
}

/**
 * Request interceptor
 * Add Authorization header if it exists
 * This configuration applies for all requests
 */
instance.interceptors.request.use(
  (reqConfig) => {
    const access_token = getCookie("token");
    if (!reqConfig.headers) {
      reqConfig.headers = {};
    }
    reqConfig.headers.Authorization = `Bearer ${access_token || ""}`;
    reqConfig.timeout = 3000000;

    return reqConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Response interceptor
 * Catch basic errors like 401 and redirect to login
 * This configuration applies for all responses
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do something with response error
    if (typeof error === "undefined") {
      // request cancelled
      // when backend server is not available at all
      //   store.dispatch(
      //     updateAlertMsg({
      //       message:
      //         "Backend services are not active. Please try again after sometime",
      //       messageType: "warning",
      //     })
      //   );
      //   "Backend services are not active. Please try again after sometime"
      // );
    } else if (typeof error.response === "undefined") {
      // when request is timeout
    } else if (error.response.status === 401) {
      // apply refresh token logic here instead of redirecting to login
      // localStorage.clear();
      // localStorage.clear();
      // deleteAllCookies();
      // store.dispatch(push("/login"));
      // alert(error);
    }

    return Promise.reject(error);
  }
);

export default instance;
