import * as actions from "../actions/types";

const initialState = {
  cityListData: [],
};

const citylistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CITY_LIST_ACTION:
      return {
        ...state,
        cityListData: action.data,
      };
    case actions.CITY_LIST_ACTION_SUCCESS:
      return {
        ...state,
        cityListData: action.data,
      };
    default: {
      return state;
    }
  }
};
export default citylistReducer;
