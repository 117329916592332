import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, CssBaseline, Toolbar } from "@material-ui/core";

import { NavLink, useLocation } from "react-router-dom";
import * as routeTo from "../../utils/routeConstants";

import dashboard from "../../assests/img/dashboard.png";
import task from "../../assests/img/Task.png";
import users from "../../assests/img/users.png";
import chat from "../../assests/img/chat.png";
import settings from "../../assests/img/settings.png";
import leads from "../../assests/img/leads.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  navLink: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#ffffff",
    },
    "& > div": {
      backgroundColor: "none",
    }
  },
  listStyle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  listText: {
    color: "black",
  },
  navIcons: {
    height: "24px",
  },
  quoteIcon: {
    color: "#4A4A4A",
    fontSize: "1.5rem",
    padding: "4px",
    border: "1px solid #4A4A4A",
    borderRadius: "50%",
  },

  divItem: {
    display: "block",
    textAlign: "center",
  },
}));

export default function BottomNav(props) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <AppBar position="fixed" color="inherit" className={classes.appBar}>
      <Toolbar>
        <CssBaseline/>
        <div className={classes.listStyle}>
          <NavLink to={`/${routeTo.DASHBOARD}`} className={classes.navLink}>
            <div className={classes.divItem}>
              {location.pathname == "/dashboard" ? (
                <img src={dashboard} className={classes.navIcons} />
              ) : (
                <img src={dashboard} className={classes.navIcons} />
              )}

              <div className={classes.listText}>Dashboard</div>
            </div>
          </NavLink>

          <NavLink to={`/${routeTo.TASK}`} className={classes.navLink}>
            <div className={classes.divItem}>
              {/* <AddIcon className={classes.quoteIcon} /> */}
              {location.pathname == "/task" ? (
                <img src={task} className={classes.navIcons} />
              ) : (
                <img src={task} className={classes.navIcons} />
              )}
              <div className={classes.listText}>TASK</div>
            </div>
          </NavLink>

          <NavLink to={`/${routeTo.USERS}`} className={classes.navLink}>
            <div className={classes.divItem}>
              {location.pathname == "/users" ? (
                <img src={users} className={classes.navIcons} />
              ) : (
                <img src={users} className={classes.navIcons} />
              )}
              <div className={classes.listText}>Users</div>
            </div>
          </NavLink>

          <NavLink to={`/${routeTo.LEADS}`} className={classes.navLink}>
            <div className={classes.divItem}>
              {location.pathname == "/leads" ? (
                <img src={leads} className={classes.navIcons} />
              ) : (
                <img src={leads} className={classes.navIcons} />
              )}

              <div className={classes.listText}>Leads</div>
            </div>
          </NavLink>
        </div>
      </Toolbar>
    </AppBar>
  );
}
