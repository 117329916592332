import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getCookie, isEmpty } from "../../utils/common";

import _ from "lodash";
import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// Update user profile API
function updateLeadDataApi(details) {
  var detailss = JSON.parse(details);
  if (detailss.id === undefined) {
    return axios.request({
      method: "post",
      url: `${urls.BASE_URL}${urls.UPDATE_LEAD_DATA}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
      data: details,
    });
  } else {
    return axios.request({
      method: "post",
      url: `${urls.BASE_URL}${urls.UPDATE_LEAD_DATA}/${detailss.id}`,
      // headers: {
      //   "Content-Type": "application/json",
      // },
      data: details,
    });
  }
}

// Update user profile action effect
export function* updateLeadDataActionEffect(updateLeadDataAction) {
  const { payload, resolve, reject } = updateLeadDataAction;

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(updateLeadDataApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      // yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    // yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update user profile action watcher
export function* updateLeadDataActionWatcher() {
  yield takeLatest(actions.UPDATE_LEAD_DATA, updateLeadDataActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateLeadDataActionWatcher)]);
}
