import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

// Update task document API
function uploadExcelDocument(params) {
    const domain = getCookie("domain");
    // console.log("upload excel sagaga", params);
    console.log("upload excel sagaga domain", domain);
    return axios.request({
        method: "post",
        url: `${urls.BASE_URL}${urls.UPLOAD_EXCEL_DOCUMENT}`,
        headers: {
            "Content-Type": "multipart/form-data",
            "domain_name": domain === "lenders" ? "" : domain,
            "apiKey": headersKeyFile.headersCommonKey.apiKey,
            "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        },
        data: params
        // data: {
        //     upload_doc: params,
        //     pipeline_id: 38
        // }
        // data: params,
    });
}

// Update task document action effect
export function* uploadExcelDocumentActionEffect(updateTaskDocumentAction) {
    const { payload, resolve, reject } = updateTaskDocumentAction;
    // console.log("payload inside excel upload", payload);
    try {
        // yield put(toggleBackDrop(true));
        const { data } = yield call(uploadExcelDocument, payload);
        // yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            // console.log("upload Excel document", data);
        } else {
            // console.log("data error excel1", data);
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        // console.log("data error exce2l", e);
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}
// Update task document action watcher
export function* uploadDocumentActionWatcher() {
    yield takeLatest(
        actions.UPLOAD_EXCEL_SUBMIT,
        uploadExcelDocumentActionEffect
    );
}

export default function* rootSaga() {
    yield all([fork(uploadDocumentActionWatcher)]);
}
