import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Market Place domain API
function saveMarketPlaceDomainApi(param) {
  console.log("params saving domain market share", param)
  console.log("params saving domain market share", param.payload)
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.SAVE_MARKET_PLACE_DOMAIN_URL}${param && param.paramID}${'/share'}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data:param.payload
  });
}

// Market Place domain effect

export function* saveMarketPlaceDomainActionEffect(marketPlaceDomain) {
  const { payload,paramID, resolve, reject } = marketPlaceDomain;
  console.log("saveMarketPlaceDomainActionEffect", marketPlaceDomain)
  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(saveMarketPlaceDomainApi, marketPlaceDomain);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      // console.log("marketPlaceShareData", data)
      return yield put({
        type: actions.MARKET_PLACE_SHARE_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.MARKET_PLACE_SHARE_ERROR,
      e,
    })
  }
}
//Market Place domain watcher
export function* saveMarketPlaceDomainActionWatcher() {
  yield takeLatest(actions.MARKET_PLACE_SHARE, saveMarketPlaceDomainActionEffect);
}

export default function* rootSaga() {
  yield all([fork(saveMarketPlaceDomainActionWatcher)]);
}
