import * as routeTo from "./utils/routeConstants";

import React, { Suspense, lazy } from "react";
// import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { CoverWrapper } from "./Loader";
import LayoutPage from "./componennts/Layout";
import PipelineFormCreatorReact from "./pages/PipelineDashboard/PipelineFormCreator.react";
import PrivateRoute from "./utils/PrivateRoutes";
import VerifyEmail from "./pages/VerifyEmail";
import { history } from "../src/store/index";
// import MiddleWare from "./MiddleWare";
import BusinessEditRulesDetails from "./pages/BusinessRules/BusinessEditRules/BusinessEditRulesDetails";
// import CRIFDetails from "./componennts/CustomerDetails/ScoreDetailsPage";
// import { getCookie } from "./utils/common";

const CustomerDetailsPage = lazy(() =>
  import("./componennts/CustomerDetails/CustomerDetailsPage")
);

const CRIFDetails = lazy(() =>
  import("./componennts/CustomerDetails/ScoreDetailsPage")
);

const RepaymentHistory = lazy(() =>
  import("./componennts/CustomerDetails/RepaymentHistory")
);

const BusinessRules = lazy(() => import("./pages/BusinessRules/BusinessRules"));
const Clients = lazy(() => import("./componennts/Clients/Clients"));

const LeadsSettingsForm = lazy(() =>
  import("./componennts/Leads/LeadsSettingsForm")
);

const RegisterationSuccessful = lazy(() =>
  import("./pages/Register/RegisterationSuccessful")
);

const BankDetails = lazy(() => import("./componennts/BankDetails/BankDetails"));

const Applicationsstagesdetails = lazy(() =>
  import("./componennts/Applicationsstagesdetails")
);
const AddleadSourceModel = lazy(() =>
  import("./componennts/AddleadSourceModel")
);
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const UsersLoginPage = lazy(() => import("./pages/UsersLogin"));
const LoginPage = lazy(() => import("./pages/Login"));
// const HomePage = lazy(() => import("./pages/Home"));
const Lmsystem = lazy(() => import("./pages/Lmsystem/Lmsystem.react"));
const Losystem = lazy(() => import("./pages/Losystem/Losystem.react"));
const DashboardPage = lazy(() => import("./pages/Dashboard/Dashboard.react"));
const ContactsPage = lazy(() => import("./pages/Contacts"));
const MobileOTPVerification = lazy(() =>
  import("./pages/Register/MobileOTPVerification")
);
const CustomerDetails = lazy(() =>
  import("./componennts/ContactsList/CustomerDetails")
);
const LeadsDetails = lazy(() => import("./pages/Leads/LeadsDetails.react"));
const ApplicationPaage = lazy(() =>
  import("./pages/Application/Application.react")
);
const type_one = lazy(() =>
  import("./company_pages/pipeline_journey/type_one/type_one.react")
);
const TaskPage = lazy(() => import("./pages/Task"));
const LeadsPage = lazy(() => import("./pages/Leads/LeadsGridView"));
const UsersPage = lazy(() => import("./pages/Users"));
const ChatPage = lazy(() => import("./pages/Chat"));
const AddTask = lazy(() => import("./pages/Task/AddTask"));
const AddUserModal = lazy(() => import("./pages/Users/AddUser/index"));
const AddPipelineForm = lazy(() =>
  import("./pages/PipelineDashboard/AddPipelineForm")
);
const AllPipelineDetailsPage = lazy(() =>
  import("./pages/PipelineDashboard/AllPipelineDetailsPage")
);

const AllPipelinePushedPage = lazy(() =>
  import("./pages/PipelineDashboard/AllPipelinePushedPage")
);

const BusinessRulesDetails = lazy(() =>
  import("./pages/BusinessRules/BusinessRulesDetails")
);
const WorkFlowList = lazy(() => import("./pages/WorkFlow/WorkFlowList"));
const WorkFlowDetails = lazy(() => import("./pages/WorkFlow/WorkFlowDetails"));

const TaskDetails = lazy(() => import("./pages/TaskDetails"));
const ApplicationDetails = lazy(() =>
  import("./pages/ApplicationDetails/ApplicationDetails.react")
);
// const Settings = lazy(() => import("./pages/Settings"));
const Settings = lazy(() => import("./pages/Settings/SettingsNewDesign.react"));

const TaskListPage = lazy(() => import("./pages/Task/TaskListPage.react"));
const CreatePassword = lazy(() =>
  import("./pages/CreatePassword/CreatePassword.react")
);
const LeadsDashboardPage = lazy(() =>
  import("./pages/LeadsDashboard/LeadsDashboard.react")
);
const PipelineDashboardPage = lazy(() =>
  import("./pages/PipelineDashboard/PiplineDashboard.react")
);
const UserDetailsProfile = lazy(() =>
  import("./componennts/UserDetailsProfile")
);

const PipelineDetails = lazy(() =>
  import("./pages/PipelineDetails/PipelineDetails.react")
);

const PartnerListProfile = lazy(() =>
  import("./pages/Partners/Partners.react")
);

const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile.react"));

const PartnersTable = lazy(() =>
  import("./pages/Partners/PartnersTable.react")
);
const PipelineSettings = lazy(() =>
  import("./pages/PipelineDetails/PipelineSettings/PipelineSettings.react")
);

const Fallback = () => {
  const containerRef = React.useRef();
  return (
    <CoverWrapper>
      <div ref={containerRef}>
        <div className="fallback-fadein"></div>
      </div>
    </CoverWrapper>
  );
};

const appRouter = (
  <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
    {/* {localurl.toString() === "#/login" ||
    localurl.toString() === "#/register" ||
    localurl.toString() === "#/" ||
    localurl.toString().includes("#/users/createpassword") ||
    localurl.toString().includes("#/forgot_password") ||
    localurl.toString().includes("#/mobile/otp") ||
    localurl.toString().includes("#/success") ? (
      ""
    ) : window.location.host.split(".")[0] === "lenders" ? (
      ""
    ) : (
      <MiddleWare />
    )} */}

    <LayoutPage>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route exact path="/">
            <Redirect to={`/${routeTo.LOGIN}`} />
          </Route>

          <Route exact path={`/${routeTo.LOGIN}`} component={LoginPage} />
          <Route
            exact
            path={`/${routeTo.FORGOTPASSWORD}`}
            component={ForgotPassword}
          />
          <Route
            exact
            path={`/${routeTo.CREATEPASSWORD}`}
            component={CreatePassword}
          />
          <Route
            exact
            path={`/${routeTo.SUCCESS}`}
            component={RegisterationSuccessful}
          />

          <Route
            exact
            path={`/${routeTo.PARTNERSTABLE}`}
            component={PartnersTable}
          />

          <Route
            exact
            path={`/${routeTo.VERIFYEMAIL}`}
            component={VerifyEmail}
          />
          <Route
            exact
            path={`/${routeTo.MobileOTPVerification}`}
            component={MobileOTPVerification}
          />
          <Route exact path={`/${routeTo.TYPE_ONE}`} component={type_one} />
          <PrivateRoute
            exact
            path={`/${routeTo.LEADSSETTINGSFORM}`}
            component={LeadsSettingsForm}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.CRIF_DETAILS}`}
            component={CRIFDetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.RepaymentHistory}`}
            component={RepaymentHistory}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.LEADSDETAILS}`}
            component={LeadsDetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.PROFILEDETAILS}`}
            component={UserDetailsProfile}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.PIPELINEDETAILS}`}
            component={PipelineDetails}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.APPLICATIONDETAILS}`}
            component={ApplicationDetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.LMSYSTEM}`}
            component={Lmsystem}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path={`/${routeTo.LOSYSTEM}`}
            component={Losystem}
          ></PrivateRoute>

          {/* <PrivateRoute
            exact
            path={`/${routeTo.HOME_PAGE}`}
            component={HomePage}
          /> */}

          <PrivateRoute
            exact
            path={`/${routeTo.APPLICATION}`}
            component={ApplicationPaage}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.USERPROFILE}`}
            component={UserProfile}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.USER_PROFILE}`}
            component={UserProfile}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.CONTACTS}`}
            component={ContactsPage}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.CUSTOMER_DETAILS}`}
            component={CustomerDetails}
          />

          <Route
            exact
            path={`/${routeTo.REGISTER_PAGE}`}
            component={LoginPage}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.CUSTOMERDETAILSPAGE}`}
            component={CustomerDetailsPage}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.ALLPIPELINEDETAILSPAGE}`}
            component={AllPipelineDetailsPage}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.ALLPIPELINEPUSHEDPAGE}`}
            component={AllPipelinePushedPage}
          />

          <Route path={`/${routeTo.BUSINESSRULES}`} component={BusinessRules} />
          <PrivateRoute
            exact
            path={`/${routeTo.Clients}`}
            component={Clients}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.BANKLISTPAGE}`}
            component={BankDetails}
          />

          <Route
            exact
            path={`/${routeTo.USERS_LOGIN_PAGE}`}
            component={UsersLoginPage}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.DASHBOARD}`}
            component={DashboardPage}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.LEADSDASHBOARD}`}
            component={LeadsDashboardPage}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.PIPELINES}`}
            component={PipelineDashboardPage}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.TASKLISTPAGE}`}
            component={TaskListPage}
          />
          <PrivateRoute exact path={`/${routeTo.TASK}`} component={TaskPage} />

          <PrivateRoute exact path={`${routeTo.LEADS}`} component={LeadsPage} />
          <PrivateRoute
            exact
            path={`/${routeTo.USERS}`}
            component={UsersPage}
          />
          <PrivateRoute exact path={`/${routeTo.CHAT}`} component={ChatPage} />
          <PrivateRoute
            exact
            path={`/${routeTo.ADDTASK}`}
            component={AddTask}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.ADDUSERMODALFORM}`}
            component={AddUserModal}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.ADDPIPELINEFORM}`}
            component={AddPipelineForm}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.BUSINESSRULESDETAILS}`}
            component={BusinessRulesDetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.BUSINESSRULESEDITDETAILS}`}
            component={BusinessEditRulesDetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.WORKFLOWLIST}`}
            component={WorkFlowList}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.WORKFLOWDETAILS}`}
            component={WorkFlowDetails}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.APPLICATIONSTAGE}`}
            component={Applicationsstagesdetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.AddleadSourceModel}`}
            component={AddleadSourceModel}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.EDITTASK}`}
            component={AddTask}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.TASKDETAILS}`}
            component={TaskDetails}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.SETTINGS}`}
            component={Settings}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.FORMCREATOR}`}
            component={PipelineFormCreatorReact}
          />
          <PrivateRoute
            exact
            path={`/${routeTo.PARTNERS}`}
            component={PartnerListProfile}
          />

          <PrivateRoute
            exact
            path={`/${routeTo.PipelineSettings}`}
            component={PipelineSettings}
          />
        </Switch>
      </Suspense>
    </LayoutPage>
  </Router>
);

export default appRouter;
