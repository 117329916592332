import * as actions from "../actions/types";

const initialState = {
  allUser: [],
  addUser: [],
  deleteUser: [],
  pipAddUserResponse: [],
  allUsers:[],
};

const allPipelineUserReducer = (state = initialState, action) => {
  switch (action.type) {  
    case actions.PIPELINE_ALL_USER:
      return {
        ...state,
        allUser: action.data,
        // allUsers: state.allUsers.map(allUser => allUser.data.id === action.data.data.id)
      };
    case actions.PIPELINE_ADD_USER_SUCCESS:
      return {
        ...state,
        pipAddUserResponse: action.data,
      };
    case actions.PIPELINE_ADD_USER:
      return {
        ...state,
        addUser: action.data,
      }
    case actions.PIPELINE_DELETE_USER:
      return {
        ...state,
        deleteUser: action.data,
      }
    default: {
      return state;
    }
  }
};
export default allPipelineUserReducer;
