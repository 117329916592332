import * as actions from "./types";

export function toggleBackDrop(openBackDrop) {
  return {
    type: actions.OPEN_BACKDROP,
    openBackDrop,
  };
}

export function updateAlertMsg(alertMsg) {
  return {
    type: actions.ALERT_MSG,
    alertMsg,
  };
}
