import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Sign-in API
function allBankCredentialListApi() {
  console.log("allBankCredentialListApi2")
  return axios.request({
    method: "get",
    url: `${urls.BASE_URL}${urls.ALL_BANK_CREDENTIAL_LIST_URL}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// Sign-in action effect
export function* allBankCredentialListActionEffect(loginAction) {
  const { payload, resolve, reject } = loginAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(allBankCredentialListApi, payload);
    yield put(toggleBackDrop(false));

    // console.log(data);
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      console.log("allBankCredentialListApi", data);
      return yield put({
        type: actions.ALL_BANK_CREDENTIAL_LIST_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    return yield put({
      type: actions.ALL_BANK_CREDENTIAL_LIST_ERROR,
      e,
    });
  }
}
// Sign-in action watcher
export function* allBankCredentialListActionWatcher() {
  yield takeLatest(actions.ALL_BANK_CREDENTIAL_LIST, allBankCredentialListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(allBankCredentialListActionWatcher)]);
}
