import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  verifyEmailButtonStyle: {
    backgroundColor: "#0A2472",
    color: "white",
    height: 30,
    // width: "120%",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#0A2472",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));

export default styles;
