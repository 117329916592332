import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";
// Add api lead source API
function addGlobalDataNotesApi(params) {
    //   const domain = getCookie("domain");
    console.log("addGlobalDataNotesApi", params)
    return axios.request({
        method: "post",
        url: `${urls.BASE_URL}${urls.ADD_GLOBAL_NOTES_DATA_LIST}`,
        // headers: {
        //     "Content-Type": "application/json",
        //     //   "domain_name": domain === "lenders" ? "" : domain,
        //     //   "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     //   "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
        data: params,
    });
}

// Add new api lead source action effect
export function* addGlobalDataNotesAction(addGlobalDataNotes) {
    const { payload, resolve, reject } = addGlobalDataNotes;

    try {
        // yield put(toggleBackDrop(true));
        const { data } = yield call(addGlobalDataNotesApi, payload);
        // yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            console.log("addGlobalDataNotesAction", data)
            return yield put({
                type: actions.ADD_GLOBAL_DATA_NOTES_SUCCESS,
                data,
            });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        // yield put(toggleBackDrop(false));
        if (reject) reject(e);
        return yield put({
            type: actions.ADD_GLOBAL_DATA_NOTES_ERROR,
            e,
        });
    }
}

//add lead source action watcher
export function* addGlobalDataNotesWatcher() {
    yield takeLatest(actions.ADD_GLOBAL_DATA_NOTES, addGlobalDataNotesAction);
}

export default function* rootSaga() {
    yield all([fork(addGlobalDataNotesWatcher)]);
}
