import * as actions from "./types";

export function updateMember(param, resolve, reject) {
  // console.log(param);
  return {
    type: actions.UPDATE_MEMBER,
    payload: param,
    resolve,
    reject,
  };
}
