import * as actions from "./types";

export function changePassword(param, resolve, reject) {
  return {
    type: actions.CHANGE_PASSWORD,
    payload: param,
    resolve,
    reject,
  };
}
