import * as actions from "../actions/types";

const initialState = {
  domainList: [],
  marketPlaceDomainList: {},
};

const marketPlaceDomainReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MARKET_PLACE_DOMAIN:
      return {
        ...state,
        domainList: action.data,
      };
    case actions.MARKET_PLACE_DOMAIN_SUCCESS:
      return {
        ...state,
        domainList: action.data,
      };
    case actions.MARKET_PLACE_DOMAIN_ERROR:
      return {
        ...state,
        domainList: action.data,
      };
    case actions.MARKET_PLACE_DOMAIN_LIST:
      return {
        ...state,
        marketPlaceDomainList: action.data,
      };
    case actions.MARKET_PLACE_DOMAIN_LIST_SUCCESS:
      return {
        ...state,
        marketPlaceDomainList: action.data,
      };
    case actions.MARKET_PLACE_DOMAIN_LIST_ERROR:
      return {
        ...state,
        marketPlaceDomainList: action.data,
      };
    default: {
      return state;
    }
  }
};
export default marketPlaceDomainReducer;
