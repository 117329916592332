import * as actions from "../actions/types";
const initialState = {
    cronUpdateStatusCheck:[]
};

const cronUpdateStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CRON_UPDATE_STATUS:
            return {
                ...state,
                cronUpdateStatusCheck: action.data,
            };
        case actions.CRON_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                cronUpdateStatusCheck: action.data,
            };
        case actions.CRON_UPDATE_STATUS_ERROR:
            return {
                ...state,
                cronUpdateStatusCheck: action.data,
            };
        default:
            return state;
    }
};
export default cronUpdateStatusReducer;
