import * as actions from "./types";

export function getBusinessRuleEdit(param, resolve, reject) {
  return {
    type: actions.GET_BUSINESS_RULE_EDIT,
    payload: param,
    resolve,
    reject,
  };
}
export function getBusinessRuleEditSuccess() {
  return {
    type: actions.GET_BUSINESS_RULE_EDIT_SUCCESS,
    // payload: param,
    // resolve,
    // reject,
  };
}
export function getBusinessRuleEditError(param, resolve, reject) {
  return {
    type: actions.GET_BUSINESS_RULE_EDIT_ERROR,
    payload: param,
    resolve,
    reject,
  };
}
