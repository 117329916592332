import {
    call,
    put,
    takeLatest,
    all,
    fork,
    select,
    takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

function lenderDashboardApi(param) {
    // console.log("edit stage template saga", paramID);
    const domain = getCookie("domain");
    // console.log("edit stage template saga1", param);
    let urlLenderDash = `${urls.BASE_URL}${urls.GET_LENDER_DASHBOARD}`
    return axios.request({
        method: "post",
        url: urlLenderDash,
        // headers: {
        //     "Content-Type": "application/json",
        //     "domain_name": domain === "lenders" ? "" : domain,
        //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
        data: param
    });
}

export function* leadsDashBoardEffect(leadsDash) {
    const { payload, resolve, reject } = leadsDash;
    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(lenderDashboardApi, payload);
        yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            // console.log("dynamic lender dashboard", data);
            if (resolve) resolve(data);
            return yield put({
                type: actions.DYNAMIC_LENDER_DASHBOARD_SUCCESS,
                data,
            });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
        return yield put({
            type: actions.DYNAMIC_LENDER_DASHBOARD_ERROR,
            e,
        });
    }
}

export function* leadsDashBoardWatcher() {
    yield takeLatest(
        actions.DYNAMIC_LENDER_DASHBOARD, leadsDashBoardEffect
    );
}

export default function* rootSaga() {
    yield all([fork(leadsDashBoardWatcher)]);
}
