import '../../../src/utils/ReactFormBuilder.css';

import React, {useContext, useLayoutEffect} from 'react';

import LayoutContext from "../../Context/LayoutContext";
import { ReactFormBuilder } from 'react-form-builder2';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import useStyles from "./PipelineFormCreator.style.js";

const PipelineFormCreator = (props) => {
  const classes = useStyles();
  const layoutContext = useContext(LayoutContext);
  
  useLayoutEffect(() => {
    layoutContext.setDisabledTopNav(false);
    layoutContext.setDisabledBottomNav(true);
  }, []);
  
  var items = [{
      key: 'Header',
      name: 'Header Text',
      icon: 'fa fa-header',
      static: true,
      content: 'Placeholder Text...'
    },
    {
      key: 'Paragraph',
      name: 'Paragraph',
      static: true,
      icon: 'fa fa-paragraph',
      content: 'Placeholder Text...'
    }
  ];
    
  return (
    <div className="row">
      <div className={classes.reactFormBuilder}>
        <label>
          Create your form for : <span></span>
        </label>
        <hr></hr>
        <ReactFormBuilder/>,
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};



export default connect(mapStateToProps, mapDispatchToProps)(PipelineFormCreator);

// ReactDOM.render(
//     <ReactFormBuilder />,
//     document.body
// )