import * as actions from "../actions/types";
const initialState = {};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_BACKDROP:
      return {
        ...state,
        openBackDrop: action.openBackDrop,
      };
    case actions.ALERT_MSG:
      return {
        ...state,
        alertMsg: action.alertMsg,
      };
    default:
      return state;
  }
};
export default commonReducer;
