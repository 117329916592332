import {
    call,
    put,
    takeLatest,
    all,
    fork,
  } from "redux-saga/effects";
  import * as actions from "../actions/types";
  import axios from "../../utils/axios";
  // import _ from "lodash";
  import * as urls from "../../utils/urls";
  import { isEmpty } from "../../utils/common";
  import { toggleBackDrop } from "../actions/commonAction";
  
  async function pipelineLogoDetailListApi(params) {
    const {id} = params
    console.log( id,"city list saga params logs");
     return axios.request({
      method: "get",
      url: `${urls.BASE_URL}${urls.CUSTOMER_LIST}/${id}`,
    });
  }
  
  export function* logoDetailListActionEffect(pipelineLogoDetailListAction) {
    const { payload, resolve, reject } = pipelineLogoDetailListAction;
  
    try {
      yield put(toggleBackDrop(true));
      const { data } = yield call(pipelineLogoDetailListApi, payload);
      yield put(toggleBackDrop(false));
      if (!isEmpty(data)) {
        if (resolve) resolve(data);
        return yield put({
          type: actions.CUSTOMER_DETAIL_ACTION_SUCCESS,
          data,
        });
      } else {
        yield put(toggleBackDrop(false));
        reject(data);
      }
    } catch (e) {
      yield put(toggleBackDrop(false));
      if (reject) reject(e);
    }
  }

  export function* logoDetailListActionWatcher() {
    yield takeLatest(actions.CUSTOMER_DETAIL_ACTION, logoDetailListActionEffect);
  }
  
  export default function* rootSaga() {
    yield all([fork(logoDetailListActionWatcher)]);
  }
  