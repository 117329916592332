import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";

// access level - List API
function getBusinessData(params) {
    const domain = getCookie("domain");
    return axios.request({
        method: "get",
        url: `${urls.BASE_URL}${urls.GET_BUSINESS_RULE_URL}`,
        // headers: {
        //     "Content-Type": "application/json",
        //     "domain_name": domain === "lenders" ? "" : domain,
        //     "apiKey": headersKeyFile.headersCommonKey.apiKey,
        //     "apiSecret": headersKeyFile.headersCommonKey.apiSecret
        // },
    });
}

// access-Level action effect
export function* getBusinessDataActionEffect(stageListAction) {
    const { payload, resolve, reject } = stageListAction;
    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(getBusinessData, payload);
        yield put(toggleBackDrop(false));
        if (!isEmpty(data)) {
            if (resolve) resolve(data);
            // console.log("getBusiness Data Saga", data);
            return yield put({
                type: actions.GET_BUSINESS_SUCCESS_DATA,
                data,
            });
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
        return yield put({
            type: actions.GET_BUSINESS_ERROR_DATA,
            e,
        });
    }
}
// access level action watcher
export function* getBusinessActionWatcher() {
    yield takeLatest(actions.GET_BUSINESS_DATA, getBusinessDataActionEffect);
}

export default function* rootSaga() {
    yield all([fork(getBusinessActionWatcher)]);
}
