import * as actions from "../actions/types";

const initialState = {
  customerBREListData: [],
};

const customerBREListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CUSTOMER_BRE_ACTION:
      return {
        ...state,
        customerBREListData: action.data,
      };
    case actions.CUSTOMER_BRE_ACTION_SUCCESS:
      return {
        ...state,
        customerBREListData: action.data,
      };
    default: {
      return { ...state, customerBREListData: [] };
    }
  }
};
export default customerBREListReducer;
