import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducers } from "./reducers/index";
import rootSaga from "./sagas/index";
// import freeze from "redux-freeze";
import { createBrowserHistory, createHashHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { configureStore } from "@reduxjs/toolkit";

let middlewares = [];

//const history = createBrowserHistory();
const history = createHashHistory();

middlewares.push(routerMiddleware(history));

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

let middleware = applyMiddleware(...middlewares);

const store = createStore(rootReducers, middleware);

sagaMiddleware.run(rootSaga);

export { store, history };
