import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Single Task Details API
function pipelineArchiveDeleteApi(id) {
  console.log("id =>", id);
  return axios.request({
    method: "delete",
    url: `${urls.BASE_URL}${urls.PIPELINE_ARCHIVE_DELETE}/${id}`,
  });
}

// Single Task Details effect
export function* pipelineArchiveDeleteActionEffect(pipelineArchiveDeleteAction) {
  const { payload, resolve, reject } = pipelineArchiveDeleteAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(pipelineArchiveDeleteApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

//Single Task Details action watcher
export function* pipelineArchiveDeleteActionWatcher() {
  yield takeLatest(actions.PIPELINE_ARCHIVE_DELETE, pipelineArchiveDeleteActionEffect);
}

export default function* rootSaga() {
  yield all([fork(pipelineArchiveDeleteActionWatcher)]);
}
