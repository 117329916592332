import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import loader from "../../assests/img/loaderGif.webp";
import Loader from "../../componennts/Backdrop/Backdrop";

const useStyles = makeStyles((theme) => ({
  backDrop: {},
}));

export default function ExampleDialogComponent() {
  const classes = useStyles();
  return (
    <Typography variant="body1">
      <img src={loader} width="100px" />
    </Typography>
  );
}
