import * as actions from "./types";

export function addBusinessRule(param, resolve, reject) {
  return {
    type: actions.ADD_BUSINESS_RULE,
    payload: param,
    resolve,
    reject,
  };
}
export function addBusinessRuleSuccess() {
  return {
    type: actions.ADD_BUSINESS_RULE_SUCCESS,
    // payload: param,
    // resolve,
    // reject,
  };
}
export function addBusinessRuleError(param, resolve, reject) {
  return {
    type: actions.ADD_BUSINESS_RULE_ERROR,
    payload: param,
    resolve,
    reject,
  };
}
