import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// add pipeline API
function addPipelineApi(params) {
  return axios.request({
    method: "post",
    url: `${urls.BASE_URL}${urls.ADD_PIPELINE}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// add pipeline action effect
export function* addPipelineActionEffect(pipelineAction) {
  const { payload, resolve, reject } = pipelineAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(addPipelineApi, payload);
    yield put(toggleBackDrop(false));

    // console.log(data);
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Pipeline action watcher
export function* addPipelineActionWatcher() {
  yield takeLatest(actions.ADD_PIPELINE, addPipelineActionEffect);
}

export default function* rootSaga() {
  yield all([fork(addPipelineActionWatcher)]);
}
